import { registerLocaleData } from "@angular/common";
import { Component } from "@angular/core";
import { AuthGuard, AuthService, ConfigurationService } from "@cloud/shared";
import localeRu from "@angular/common/locales/ru";


@Component({
  selector: "app-root",
  template: "<router-outlet></router-outlet> <app-main-page-loader *ngIf=\"(authGuard.loader)\"></app-main-page-loader>"
})
export class AppComponent {
  constructor(public authGuard: AuthGuard,
              private authService: AuthService) {
    registerLocaleData(localeRu, "ru");
    authService.runInitialLoginSequence().then(() => true);
  }
}
