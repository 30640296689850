import { Injectable, NgModule } from "@angular/core";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { TimepickerModule } from "ngx-bootstrap/timepicker";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgxMaskModule } from "ngx-mask";
import { HttpClientModule } from "@angular/common/http";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { ButtonsModule } from "ngx-bootstrap/buttons";
import { TabsModule } from "ngx-bootstrap/tabs";
import { FileSaverModule } from "ngx-filesaver";
import { NgxColorsModule } from "ngx-colors";
import { AlertModule } from "ngx-bootstrap/alert";
import { ModalModule } from "ngx-bootstrap/modal";
import { SortableModule } from "ngx-bootstrap/sortable";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { LetDirective } from "@cloud/shared";
import { MaterialModule } from "./material.module";
import { HAMMER_GESTURE_CONFIG, HammerGestureConfig, HammerModule } from "@angular/platform-browser";
import * as Hammer from "hammerjs";
import { DndModule } from "@ng-dnd/core";
import { HTML5Backend } from "@ng-dnd/multi-backend";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";


@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
  override overrides = <any>{
    swipe: { direction: Hammer.DIRECTION_HORIZONTAL},
    pinch: { enable: false },
    rotate: { enable: false }
  };
}

@NgModule({
  declarations: [
    LetDirective
  ],
  imports: [
    NgxMaskModule.forRoot(),
    AlertModule.forRoot(),
    ModalModule.forRoot(),
    SortableModule.forRoot(),
    HammerModule,
    MaterialModule,
    DndModule.forRoot({backend: HTML5Backend})
],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    HttpClientModule,
//todo refactor to material UI
    BsDropdownModule,         //     deprecated
    AlertModule,              //     deprecated
    BsDatepickerModule,       //     deprecated
    ButtonsModule,            //     deprecated
    TimepickerModule,         //     deprecated
    NgxMaskModule,            //     ???
    TabsModule,               //     deprecated
    FileSaverModule,
    NgxColorsModule,
    ModalModule,              //     deprecated
    SortableModule,           //     deprecated
    InfiniteScrollModule,
    LetDirective,
    MaterialModule,
    DndModule,
    NgxSkeletonLoaderModule,
  ],
  providers: [
    {
    provide: HAMMER_GESTURE_CONFIG,
    useClass: MyHammerConfig
  }
  ]
})
export class DependenciesModule {
}
