import { Injectable } from "@angular/core";
import { Observable, tap } from "rxjs";
import { ErrorCode, ErrorContent } from "../../model/error.model";
import { PageLite } from "../../model/page-lite.model";
import { ErrorsAdapterService } from "./errors.adapter.service";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { EnvironmentApiUrlService } from "@cloud/shared";
import { TimestampService } from "../timestamp.service";
import { ErrorsMapperService } from "./errors.mapper.service";

@Injectable({
  providedIn: "root"
})
export class ErrorsServiceApi implements ErrorsAdapterService {
  constructor(private http: HttpClient,
              private envUrl: EnvironmentApiUrlService,
              private timestampService: TimestampService,
              private mapper: ErrorsMapperService) {
  }

  public getLastStationErrors(id: string, count: number, offset: number, filters: Map<string, any>): Observable<PageLite> {
    let params = new HttpParams();
    params = params.append("count", count);
    params = params.append("offset", offset);
    if (filters.get("level")) params = params.append("level", filters.get("level"));
    if (filters.get("is_resolved") !== undefined) params = params.append("is_resolved", filters.get("is_resolved"));
    return this.http.get<PageLite>(this.envUrl.apiUrlV1 + `/errors/stations/${id}`, { params: params });
  }

  public getErrorCodes(): Observable<ErrorCode[]> {
    return this.http.get<ErrorCode[]>(this.envUrl.apiUrlV1 + `/errors/codes`);
  }

  public putErrorCode(errorCode: ErrorCode): Observable<ErrorCode> {
    return this.http.put<ErrorCode>(this.envUrl.apiUrlV1 + `/errors/codes/${errorCode.code}`, errorCode);
  }

  public postErrorCode(errorCode: ErrorCode): Observable<ErrorCode> {
    return this.http.post<ErrorCode>(this.envUrl.apiUrlV1 + `/errors/codes`, errorCode);
  }

  public patchSelectedErrors(errors: ErrorContent[]): Observable<ErrorContent[]> {
    const params = new HttpParams({ fromObject: { status: "resolved" } });

    const ids = this.mapper.mapErrorContentToIDs(errors);

    return this.http.patch<ErrorContent[]>(this.envUrl.apiUrlV1 + `/errors`, ids, { params });

  }

}
