import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { AuthConfig, OAuthEvent, OAuthService } from "angular-oauth2-oidc";
import { HttpClient } from "@angular/common/http";
import jwtDecode from "jwt-decode";

import { EnvironmentApiUrlService } from "./environment-api-url.service";
import { BehaviorSubject, combineLatest, map, Observable, ReplaySubject, Subject } from "rxjs";
import { User } from "../model/user.model";
import { ConfigurationService } from "../config/configuration.service";
import { OAuthConfig } from "../config/oAuth-config";

@Injectable({
  providedIn: "root"
})
export class AuthService {

  private isAuthenticatedSubject$ = new BehaviorSubject<boolean>(false);
  public isAuthenticated$ = this.isAuthenticatedSubject$.asObservable();

  private isDoneLoadingSubject$ = new ReplaySubject<boolean>();
  public isDoneLoading$ = this.isDoneLoadingSubject$.asObservable();

  constructor(private router: Router,
              private oauthService: OAuthService,
              private configService: ConfigurationService) {
    configService.configurationReceived$.subscribe(flag => {
      if (flag) this.configSso();
    })
  }

  configSso() {
    const config = this.configService.getValue('oAuthConfig') as AuthConfig
    this.oauthService.configure(config);
    this.oauthService.events
      .subscribe({
        next: () => {
          this.isAuthenticatedSubject$.next(this.oauthService.hasValidAccessToken());
        },
        error: () => {
          this.isAuthenticatedSubject$.next(this.oauthService.hasValidAccessToken())
        }
      });
    this.oauthService.setupAutomaticSilentRefresh();
  }


  public runInitialLoginSequence(): Promise<void> {
    return this.oauthService.loadDiscoveryDocumentAndTryLogin()
      .then(() => {
        console.debug()
        this.isDoneLoadingSubject$.next(true);
        //todo fix redirect address
        this.router.navigate(["index/station/dashboard"]).then(() => console.log("Authorized"));
      })
      .catch(() => this.isDoneLoadingSubject$.next(true));
  }

  login() {
    this.oauthService.initCodeFlow();
  }

  logout() {
    this.oauthService.logOut();
  }


  get identityClaims() {
    return this.oauthService.getIdentityClaims();
  }

  get roles() {
    const token = this.oauthService.getAccessToken();
    const { realm_access }: any = jwtDecode(token);
    return realm_access.roles;
  }

  getEvents() {
    return this.oauthService.events.subscribe(event => console.debug("event: ", event));
  }

}
