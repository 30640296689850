import { Injectable } from "@angular/core";
import { map, Observable, Subject } from "rxjs";
import { PassportService } from "./passport.service";
import { ChartInterface, Line } from "../model/chart-interface.model";
import { HttpClient } from "@angular/common/http";
import { EnvironmentApiUrlService } from "./environment-api-url.service";
import { ChartTypes, LineTypes } from "../enums/main.enum";
import { ChartConstructorService } from "./chart-constructor.service";
import { Board } from "../model/board.model";
import { Metric } from "../model/metric.model";
import { Detail } from "../model/detail.model";
import { Report } from "../model/report.model";
import { EventModel } from "../model/event.model";
import { IntervalModel } from "../model/interval.model";

@Injectable({
  providedIn: "root"
})
export class ChartsService {
  //todo make time localizing

  private chartObserver = new Subject<ChartInterface>();

  public chartSubscriber$ = this.chartObserver.asObservable();

  public userId!: string;

  constructor(private passport: PassportService,
              private http: HttpClient,
              private envUrl: EnvironmentApiUrlService,
              private chartConstructor: ChartConstructorService) {
    this.userId = passport.userId;
  }

  randomizeColor() {
    return "#" + Math.floor(Math.random()*16777215).toString(16).padStart(6, '0').toUpperCase();
  }

  emitChart(chart: ChartInterface) {
    this.chartObserver.next(chart);
  }

  saveBoard(board: Board): Observable<Board> {
    board.charts = board.charts.map(chart => {
      chart.lines = chart.lines.map(line => {
        line.metric_id = line.metric.id;
        return line;
      });
      return chart;
    });
    return this.http.post<Board>(this.envUrl.apiUrlV1 + `/chart-board/owner/${this.userId}`, board);
  }

  updateBoard(id: string, board: Board): Observable<Board> {
    board.charts = board.charts.map(chart => {
      chart.lines = chart.lines.map(line => {
        line.metric_id = line.metric.id;
          return line;
      });
      return chart;
    });
    return this.http.put<Board>(this.envUrl.apiUrlV1 + `/chart-board/${id}/update`, board);
  }

  deleteBoard(boardId: string) {
    return this.http.delete(this.envUrl.apiUrlV1 + `/chart-board/${boardId}`);
  }

  getBoardPatterns(): Observable<{[key: string]: string}> {
    return this.http.get<any>(this.envUrl.apiUrlV1 + `/chart-board/owner/${this.userId}`)
      .pipe(map(response => {
        return response.boards;
      }));
  }

  getSelectedBoard(id: string): Observable<Board> {
    return this.http.get<Board>(this.envUrl.apiUrlV1 + `/chart-board/${id}`);
  }

  getMetricEventsWithInterval(id: string, interval: IntervalModel, threshold = 15): Observable<Metric> {
    return this.http.get<Metric>(this.envUrl.apiUrlV1 + `/metrics/${id}/interval`, {
      params: {
        start: interval.start,
        end: interval.end,
        threshold: threshold
      }
    });
  }

  getMetricDetails(id: string): Observable<Detail[]> {
    return this.http.get<Detail[]>(this.envUrl.apiUrlV1 + `/specifications/metrics/${id}/details`);
  }


  createBoard(metrics: Metric[], name?: string, singleChart = false): Board {
    return {
      name: `${name || "Новая доска"}`,
      charts: singleChart ? [this.createChartFromMetrics(metrics)] : this.createChartsFromMetrics(metrics)
    };
  }

  createChartFromMetrics(metrics: Metric[]): ChartInterface {
    const lines: Line[] = metrics.map((metric, i) => ({
      color: i ? this.randomizeColor() : "#008cc7",
      type: LineTypes.LINEAR,
      name: metric.fullName!,
      metric
    }));

    return {
      name: `График ${metrics[0].localName || metrics[0].name}`,
      type: ChartTypes.LINE,
      lines
    }
  }

  createChartsFromMetrics(metrics: Metric[]): ChartInterface[] {
    return metrics.map((metric) => {
      const chartMetric: Line = {
        color: "#008cc7",
        type: LineTypes.LINEAR,
        name: metric.fullName!,
        metric: metric
      };
      return {
        name: `График ${metric.localName || metric.name}`,
        type: ChartTypes.LINE,
        lines: [chartMetric]
      };
    });
  }

  createChartConfig(chartInterface: ChartInterface, threshold: number) {
    return this.chartConstructor.createMetricChartConfig(chartInterface, threshold);
  }

  createReportsCharts(reports: Report[][]) {
    const charts: any[] = [];
    const chartInterfaces: ChartInterface[] = [];

    reports.forEach((report) => {

      report.forEach((rep) => {
        if (rep.body.content && rep.body.content.length > 0) {

          const chartInterface: ChartInterface = {
            name: rep.location,
            type: ChartTypes.LINE,
            lines: [{
              name: "",
              type: LineTypes.LINEAR,
              color: "#008cc7",
              metric: {
                fullName: "",
                id: "",
                name: String(rep.body!.content[0].name),
                localName: String(rep.body!.content[0].localName),
                unit: rep.body!.content[0].unit,
                details: [],
                events: []
              }
            }]
          };

          chartInterfaces.push(chartInterface);

          if (rep.body && rep.body.content) rep.body.content.forEach((row: any) => {
            if (row.date) {
              const event: EventModel = {
                value: row.value,
                timestamp: row.date
              };
              chartInterfaces[chartInterfaces.length - 1].lines[0].metric.events!.push(event);
            }
          });

          //creating chart

          const chart = this.chartConstructor.createMetricChartConfig(chartInterfaces[chartInterfaces.length - 1]);
          charts.push(chart);


        }
      });

    });
    return charts;
  }

}
