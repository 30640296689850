import {Injectable} from "@angular/core";
import {Widget, WidgetService, WidgetTypes} from "@cloud/shared";
import {map, Observable, tap} from "rxjs";
import {Chart, ChartConfiguration} from "chart.js";
import {WidgetAdapterService} from "./widget.adapter.service";
import {IrregularityService} from "../widget-constructor/irregularity.service";
import {AampPumpService} from "../widget-constructor/aamp-pump.service";
import {BlowerService} from "../widget-constructor/blower.service";
import {FlowmeterVolumeService} from "../widget-constructor/flowmeter-volume.service";
import {FlowrateService} from "../widget-constructor/flowrate.service";
import {TemperatureService} from "../widget-constructor/temperature.service";

@Injectable({
  providedIn: "root"
})
export class WidgetDataService implements WidgetService {

  constructor(private api: WidgetAdapterService,
              private irregularity: IrregularityService,
              private aamp: AampPumpService,
              private blower: BlowerService,
              private flowmeterVolume: FlowmeterVolumeService,
              private flowrate: FlowrateService,
              private temperature: TemperatureService) {
  }

  getDemoWidget(type: WidgetTypes): ChartConfiguration | null {
    let service = null;
    switch (type) {
      case WidgetTypes.AAMP_PUMPS:
        service = this.aamp;
        break;
      case WidgetTypes.BLOWER:
        service = this.blower;
        break;
      case WidgetTypes.FLOW_DAILY_FLOWRATE:
        service = this.flowrate;
        break;
      case WidgetTypes.FLOW_DAILY_IRREGULARITY:
        service = this.irregularity;
        break;
      case WidgetTypes.FLOW_VOLUME:
        service = this.flowmeterVolume;
        break;
      case WidgetTypes.LOGS:
        break;
      case WidgetTypes.TEMPERATURE:
        service = this.temperature;
        break;
    }
    if (service) return service.createDemoWidgetConfig();
    return null;
  }


  //--------------------------IRREGULARITY--------------------------------------
  getFlowMeterIrregularityWidget(id: string): Observable<ChartConfiguration> {
    return this.api.getDailyIrregularityForLastWeekRequest(id)
      .pipe(map(widget => {
        return this.irregularity.createWidgetConfig(widget);
      }));
  }

  updateIrregularityWidgetDataset(chart: Chart, id: string) {
    return this.api.getDailyIrregularityForLastWeekRequest(id)
      .pipe(tap(widget => this.irregularity.updateWidgetDataset(chart, widget)));
  }

  //--------------------------IRREGULARITY--------------------------------------
  //--------------------------FLOWRATE------------------------------------------
  getFlowMeterFlowrateWidget(id: string): Observable<ChartConfiguration> {
    return this.api.getDailyFlowrateForLastWeekRequest(id)
      .pipe(map(widget => {
        return this.flowrate.createWidgetConfig(widget);
      }));
  }

  updateFlowMeterFlowrateWidgetDataset(chart: Chart, id: string) {
    return this.api.getDailyFlowrateForLastWeekRequest(id)
      .pipe(tap(widget => this.flowrate.updateWidgetDataset(chart, widget)));
  }

  //--------------------------FLOWRATE------------------------------------------
  //--------------------------FLOWMETER_VOLUME--------------------------------------------
  getFlowmeterVolumeWidget(id: string): Observable<ChartConfiguration> {
    return this.api.getFlowmeterVolumeRequest(id)
      .pipe(map(widget => {
        return this.flowmeterVolume.createWidgetConfig(widget);
      }));
  }

  updateFlowmeterVolumeWidgetDataset(chart: Chart, id: string) {
    return this.api.getFlowmeterVolumeRequest(id)
      .pipe(tap(widget => this.flowmeterVolume.updateWidgetDataset(chart, widget)));
  }

  //--------------------------FLOWMETER_VOLUME---------------------------------------------
  //--------------------------TEMPERATURE---------------------------------------
  getTemperatureWidget(id: string): Observable<ChartConfiguration> {
    return this.api.getTemperatureRequest(id)
      .pipe(map(widget => {
        return this.temperature.createWidgetConfig(widget);
      }));
  }

  updateTemperatureWidgetDataset(chart: Chart, id: string) {
    return this.api.getTemperatureRequest(id)
      .pipe(tap(widget => this.temperature.updateWidgetDataset(chart, widget)));
  }

  //--------------------------TEMPERATURE---------------------------------------
  //-------------------------------BLOWER---------------------------------------
  getBlowerWidget(id: string): Observable<[ChartConfiguration, Widget]> {
    return this.api.getBlowerRequest(id)
      .pipe(map(widget => [this.blower.createWidgetConfig(widget), widget]));
  }

  updateBlowerWidgetDataset(chart: Chart, id: string) {
    return this.api.getBlowerRequest(id)
      .pipe(tap(widget => this.blower.updateWidgetDataset(chart, widget)));
  }

  //-------------------------------BLOWER---------------------------------------
  //-------------------------------AAMP-----------------------------------------
  getAAMPPumpWidget(id: string): Observable<ChartConfiguration> {
    return this.api.getAAMPPumpRequest(id)
      .pipe(map(widget => {
        return this.aamp.createWidgetConfig(widget);
      }));
  }

  updateAAMPPumpWidgetDataset(chart: Chart, id: string) {
    return this.api.getAAMPPumpRequest(id)
      .pipe(tap(widget => this.aamp.updateWidgetDataset(chart, widget)));
  }

  //-------------------------------AAMP-----------------------------------------
}
