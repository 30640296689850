import { Injectable } from "@angular/core";
import { ChartTypes, Widget } from "@cloud/shared";
import { BubbleDataPoint, Chart, ChartConfiguration, ChartTypeRegistry, ScatterDataPoint } from "chart.js";
import { customTooltips } from "./plugins.service";
import { WidgetConstructorAbstractService } from "./widget-constructor.abstract.service";

@Injectable({
  providedIn: "root"
})
export class FlowrateService implements WidgetConstructorAbstractService {
  createDemoWidgetConfig(): ChartConfiguration<keyof ChartTypeRegistry, (number | ScatterDataPoint | BubbleDataPoint | null)[], unknown> {
    const widget: Widget = {
      id: "device_id",
      details: { rate: 0, legend: "полное наименование устройства" },
      data: [
        { label: "05.03", value: 10 },
        { label: "06.03", value: 20 },
        { label: "07.03", value: 30 },
        { label: "08.03", value: 40 },
        { label: "09.03", value: 50 },
        { label: "10.03", value: 60 },
        { label: "11.03", value: 70 }
      ]
    };
    return this.createWidgetConfig(widget);
  }

  createWidgetConfig(widget: Widget): ChartConfiguration {
    return {
      type: ChartTypes.BAR,
      data: this.createWidgetDataset(widget),
      options: this.createWidgetOptions(widget)
    };
  }

  private createWidgetOptions(widget: Widget): ChartConfiguration["options"] {
    return {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        x: {
          // offset: true,
          // grid: {offset: false},
          // stacked: false,
          // time: {
          //   tooltipFormat: 'd MMMM'
          // },
          // adapters: {
          //   date: {
          //     locale: ru
          //   }
          // }
        },
        y: {
          beginAtZero: true,
          suggestedMax: this.calculateMax(widget),
          ticks: {
            stepSize: this.calculateStepSize(widget)
          }
        }
      },
      plugins: {
        tooltip: {
          enabled: false,
          mode: "index",
          position: "nearest",
          external: customTooltips
        },
        legend: {
          display: true
          // position: "top"
        },
        title: {
          display: false
        }
      }
    };
  }

  private calculateMax(widget: Widget): number {
    const max = Math.max(...widget.data.map(point => point.value));
    switch (true) {
      case max < 50:
        return max % 5;
      case max < 100:
        return max % 10;
      case max < 300:
        return max % 20;
      case max < 500:
        return max % 50;
      case max < 1000:
        return max % 100;
      case max < 2000:
        return max % 200;
      default:
        return max;
    }
  }

  private calculateStepSize(widget: Widget): number | undefined {
    const max = widget.data.sort((a, b) => a.value - b.value)[0].value;
    switch (true) {
      case max < 20:
        return 1.0;
      case max < 50:
        return 2.5;
      case max < 100:
        return 5.0;
      case max < 300:
        return 10.0;
      case max < 500:
        return 25.0;
      case max < 1000:
        return 50.0;
      case max < 2000:
        return 100.0;
      default:
        return undefined;
    }
  }

  private createWidgetDataset(widget: Widget) {
    const labels = widget.data.map(point => point.label);
    return {
      labels: labels,
      datasets: [
        {
          label: widget.details.legend || "Виджет",
          data: widget.data.map(point => {
            return point.value;
          }),
          backgroundColor: "#007bc1"
        }
      ]
    };
  }

  updateWidgetDataset(chart: Chart, widget: Widget) {
    chart.data.datasets.forEach((dataset: any) => {
      dataset.data = chart.data.labels!.map((_value, index) => widget.data[index].value);
    });
    chart.update();
  }
}
