import {inject, Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {EnvironmentApiUrlService} from "../environment-api-url.service";
import {forkJoin, map, mergeMap, Observable, ObservableInput, of} from "rxjs";
import {NewAltabioStationManually} from "../../model/station-interface.model";
import {CloudUserType, SearchTypes, SpecLevels} from "../../enums/main.enum";
import {ConstructorService} from "../constructor.service";
import {Page} from "../../model/page.model";
import {CloudUser, CloudUserHeader} from "../../model/user.model";
import {AdminMapperService} from "./admin.mapper.service";
import {Station} from "../../model/station.model";


@Injectable({
  providedIn: "root"
})
export class AdminDataService {

  mapper = inject(AdminMapperService)

  constructor(private http: HttpClient,
              private envUrl: EnvironmentApiUrlService,
              private constructorService: ConstructorService) {
  }

  fetchCloudUserPages(filter?: CloudUserType[], offset = 0, count = 50): Observable<Page> {
    if (filter?.length === 0 || 3) filter = undefined;
    try {
      return forkJoin(filter!.map(filter => {
        return this.http.get<Page>(this.envUrl.apiUrlV1 + "/admin/cloud-users", {
          params: {
            filter: filter,
            offset: offset,
            count: count
          }
        });
      })).pipe(map(pages => {
        pages[0].content = [...pages.map(p => p.content)];
        return pages[0];
      }));
    } catch {
      return this.http.get<Page>(this.envUrl.apiUrlV1 + "/admin/cloud-users", {
        params: {
          filter: "all",
          offset: offset,
          count: count
        }
      });
    }
  }

  // getUserInfo(id: string): Observable<User> {
  //  return this.http.get<User>(this.envUrl.apiUrl + `/persons/${id}`)
  // }
  //
  // getStations(id: string): Observable<Station[]> {
  //   return this.http.get<Station[]>(this.envUrl.apiUrl + `/stations/owners/${id}`)
  // }
  //
  // getUserOrganization(email: string): Observable<Organization> {
  //   return this.http.get<Organization>(this.envUrl.apiUrl + `/companies`, {
  //     params: {
  //       email: email
  //     }
  //   })
  // }
  //
  // getOrganizationInfo(uuid: string): Observable<Organization> {
  //   return this.http.get<Organization>(this.envUrl.apiUrl + `/companies/${uuid}`)
  // }

  getCloudUserStations(id: string, userType: string): Observable<any> {
    return this.http.get(this.envUrl.apiUrlV1 + `/admin/cloud-users/${id}`, {
      params: {
        userType: userType
      }
    });
  }

  getCloudUserInfo(id: string, userType: string): Observable<CloudUser> {
    return this.http.get<CloudUser>(this.envUrl.apiUrlV1 + `/admin/cloud-users/${id}`, {
      params: {
        userType: userType
      }
    });
  }

  manualAddABStationAndBindToUser(value: NewAltabioStationManually, ownerId?: string): Observable<NewAltabioStationManually> {
    if (ownerId) {
      return this.http.post<NewAltabioStationManually>(this.envUrl.apiUrlV1 + "/admin/stations/manually", value).pipe(
        map(station => {
          return station;
        }),
        mergeMap<NewAltabioStationManually, ObservableInput<any>>(station => {
          return this.http.put(this.envUrl.apiUrlV1 + `/stations/owners/${ownerId}`, {
            stationId: station.id
          }, {
            params: {
              action: "bind"
            }
          });
        })
      );
    } else return this.http.post<NewAltabioStationManually>(this.envUrl.apiUrlV1 + "/admin/stations/manually", value);
  }


  getSearchedItems(query: string, searchType: SearchTypes, filter = "all", offset = 0, count = 25): Observable<any> {
    switch (searchType) {

      case SearchTypes.SPECIFICATIONS: {
        return this.constructorService.getAllSpecificationsBySpecLevel([SpecLevels.ALL], query, offset, count) as Observable<Page>;
      }

      case SearchTypes.USERS: {
        return this.http.get<CloudUserHeader[]>(this.envUrl.apiUrlV1 + `/cloud-users/search`, {
          params: {
            filter: filter,
            query: encodeURIComponent(query),
            offset: offset,
            count: count
          }
        });
      }
      default: {
        return of("Список пуст.");
      }
    }
  }

  getCloudStations(offset = 0, count = 50, filter?: string): Observable<Page> {
    return this.http.get<Page>(this.envUrl.apiUrlV1 + `/admin/stations`, {
      params: {
        offset: offset,
        count: count
      }
    });
    // return of(this.pageWithStationsMock)
  }

  bindStationToOwner(stationId: string, ownerId: string, action = "bind") {
    return this.http.put(this.envUrl.apiUrlV1 + `/admin/stations/owners/${ownerId}`, {stationId: stationId}, {
      params: {
        action: action
      }
    });
  }

  updateCoupledSpecifications(station: Station, formValue: {parentId: string, childId: string, deleted: boolean}[]): Observable<any> {
    const requestBody = this.mapper.mapFormValueToCoupledSpecificationsRequest(station, formValue)
    return this.http.put(this.envUrl.apiUrlV1 + `/specifications/coupled`, requestBody)
  }
}
