import { forkJoin, Observable } from "rxjs";
import { NotificationModel } from "../../model/notification.model";
import { NotificationsAdapterService } from "./notifications.adapter.service";
import { inject, Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { EnvironmentApiUrlService } from "@cloud/shared";

@Injectable({
  providedIn: "root"
})
export class NotificationsServiceApi implements NotificationsAdapterService {
  // readonly http = inject(HttpClient);
  // readonly envUrl = inject(EnvironmentApiUrlService);

  constructor(private http: HttpClient,
              private envUrl: EnvironmentApiUrlService) {
  }

  getNotifications(id: string): Observable<NotificationModel[]> {
    return this.http.get<NotificationModel[]>(this.envUrl.apiUrlV1 + `/notifications/${id}/settings`);
  }

  putNotifications(id: string, formValue: NotificationModel[]): Observable<NotificationModel[]> {
    const requests: Observable<NotificationModel>[] = formValue
      .filter(value => value.level !== "CRITICAL")
      .map(value => this.http.put<NotificationModel>(this.envUrl.apiUrlV1 + `/notifications/${id}/settings`, value))
    return forkJoin(requests);
  }


}
