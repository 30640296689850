import {animate, state, style, transition, trigger} from "@angular/animations";


// анимация для изменения окна входа в систему на окно регистрации

export const routeTransitionAnimations = trigger('fade', [
  state('void', style({opacity: 0})),
  transition('void <=> *', [
    animate(500)
  ])
]);

export const elementFastTransition = trigger('fast-fade', [
  state('void', style({opacity: 0})),
  transition('void <=> *', [
    animate(150)
  ])
]);
