
  export function customTooltips(context: any) {
    let tooltipEl = document.getElementById("chartjs-tooltip");

    // Create element on first render
    if (!tooltipEl) {
      tooltipEl = document.createElement("div");
      tooltipEl.id = "chartjs-tooltip";
      tooltipEl.innerHTML = "<table></table>";
      document.body.appendChild(tooltipEl);
    }

    // Hide if no tooltip
    const tooltipModel = context.tooltip;
    if (tooltipModel.opacity === 0) {
      tooltipEl.style.opacity = "0";
      return;
    }

    // Set caret Position
    tooltipEl.classList.remove("above", "below", "no-transform");
    tooltipEl.classList.add("tooltip");
    if (tooltipModel.yAlign) {
      tooltipEl.classList.add(tooltipModel.yAlign);
    } else {
      tooltipEl.classList.add("no-transform");
    }

    function getBody(bodyItem: { lines: any; }) {
      return bodyItem.lines;
    }

    // Set Text
    if (tooltipModel.body) {
      const titleLines = tooltipModel.title || [];
      const bodyLines = tooltipModel.body.map(getBody);

      let innerHtml = "<thead>";

      titleLines.forEach(function(title: string) {
        innerHtml += "<tr><th>" + title + "</th></tr>";
      });
      innerHtml += "</thead><tbody>";

      bodyLines.forEach(function(body: string, i: string | number) {
        const colors = tooltipModel.labelColors[i];
        let style = "background:" + colors.backgroundColor;
        style += "; border-color:" + colors.borderColor;
        style += "; border-width: 2px";
        const span = "<span style=\"" + style + "\">" + body + "</span>";
        innerHtml += "<tr><td>" + span + "</td></tr>";
      });
      innerHtml += "</tbody>";

      const tableRoot = tooltipEl.querySelector("table");
      if (tableRoot) tableRoot.innerHTML = innerHtml;
    }

    const position = context.chart.canvas.getBoundingClientRect();
    const bodyFont = tooltipModel.options.bodyFont;

    const tooltipPosX = position.left + window.scrollX + tooltipModel.caretX;

    // Display, position, and set styles for font
    tooltipEl.style.opacity = '1';
    if (document.documentElement.clientWidth - tooltipPosX <= tooltipModel.width) {
      tooltipEl.style.left = tooltipPosX - tooltipModel.width + 'px';
    } else tooltipEl.style.left = tooltipPosX + 'px';
    tooltipEl.style.top = position.top + window.scrollY + tooltipModel.caretY + 'px';
    tooltipEl.style.font = bodyFont.string;
    tooltipEl.style.padding = tooltipModel.padding + "px " + tooltipModel.padding + "px";
    tooltipEl.style.pointerEvents = "none";
  }
