import { Observable } from "rxjs";
import { NotificationModel } from "../../model/notification.model";
import { forwardRef, Injectable } from "@angular/core";
import { NotificationsServiceMock } from "./notifications.service.mock";
import { NotificationsServiceApi } from "./notifications.service.api";

@Injectable({
  providedIn: "root",
  useClass: forwardRef(() => NotificationsServiceApi)
})
export abstract class NotificationsAdapterService{
  abstract getNotifications(id: string): Observable<NotificationModel[]>

  abstract putNotifications(id: string, formValue: NotificationModel[]): Observable<NotificationModel[]>
}
