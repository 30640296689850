import { Injectable } from "@angular/core";
import { WidgetAdapterService } from "./widget.adapter.service";
import { Widget } from "../../model/widget.model";
import { map, Observable } from "rxjs";
import { EnvironmentApiUrlService } from "@cloud/shared";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "root"
})

export class WidgetServiceApi implements WidgetAdapterService {

  constructor(private envUrl: EnvironmentApiUrlService,
              private http: HttpClient) {
  }

  getDailyIrregularityForLastWeekRequest(id: string): Observable<Widget> {
    const req = this.envUrl.apiUrlV1 + `/widgets/flowmeters/irregularity`;
    let final;
    if (id) final = this.http.get<Widget>(req, { params: { id: id } });
    else final = this.http.get<Widget>(req);
    return final.pipe(map(widget => {
      widget.details.max = parseFloat(String(widget.details.max).replace(",", "."));
      widget.details.min = parseFloat(String(widget.details.min).replace(",", "."));
      return widget;
    }));
  }

  getDailyFlowrateForLastWeekRequest(id: string): Observable<Widget> {
    const req = this.envUrl.apiUrlV1 + `/widgets/flowmeters/flowrate`;
    if (id) return this.http.get<Widget>(req, { params: { id: id } });
    return this.http.get<Widget>(req);
  }

  getFlowmeterVolumeRequest(id: string): Observable<Widget> {
    const req = this.envUrl.apiUrlV1 + `/widgets/flowmeters/volume`;
    if (id) return this.http.get<Widget>(req, { params: { id: id } });
    return this.http.get<Widget>(req);
  }

  getTemperatureRequest(id: string): Observable<Widget> {
    const req = this.envUrl.apiUrlV1 + `/widgets/temperature`;
    if (id) return this.http.get<Widget>(req, { params: { id: id } });
    return this.http.get<Widget>(req);
  }

  getBlowerRequest(id: string): Observable<Widget> {
    const req = this.envUrl.apiUrlV1 + `/widgets/blowers`;
    if (id) return this.http.get<Widget>(req, { params: { id: id } });
    return this.http.get<Widget>(req);
  }


  public getAAMPPumpRequest(id: string): Observable<Widget> {
    const req = this.envUrl.apiUrlV1 + `/widgets/aamp/pumps`;
    let final;
    if (id) final = this.http.get<Widget>(req, { params: { id: id } });
    else final = this.http.get<Widget>(req);
    return final.pipe(map(widget => {
      widget.data = widget.data.map(d => {
        d.label = d.label.replace("Насос рециркуляции", "НР");
        return d;
      });
      return widget;
    }));
  }

  // https://dev.alta-cloud.ru/api/v1/widgets/aamp/pums?id=

}
