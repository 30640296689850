import { APP_INITIALIZER, LOCALE_ID, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { AppRoutingModule } from "./app-routing.module";
import { Error403Component } from "./ui/components/error403/error403.component";
import { Error404Component } from "./ui/components/error404/error404.component";
import { UiModule } from "./ui/ui.module";
import { OAuthModule } from "angular-oauth2-oidc";
import { environment } from "../environments/environment";
import { DependenciesModule } from "./core/dependencies.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { ErrorsInterceptor } from "@cloud/shared";
import { AppComponent } from "./app.component";
import { registerLocaleData } from "@angular/common";
import localeRu from "@angular/common/locales/ru";
import { ConfigurationService } from "@cloud/shared";

registerLocaleData(localeRu, "ru");

export function initApp(configurationService: ConfigurationService) {
  return () => configurationService.load();
}

@NgModule({
  declarations: [
    AppComponent,
    Error403Component,
    Error404Component
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    UiModule,
    AppRoutingModule,
    DependenciesModule,
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: [environment.apiUrl],
        sendAccessToken: true
      }
    })
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initApp,
      multi: true,
      deps: [ConfigurationService]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorsInterceptor,
      multi: true
    },
    {
      provide: LOCALE_ID,
      useValue: "ru"
    }
  ],
  exports: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
