<div>
</div>
<div class="main">
  <div class="buttons">
    <div class="py-4">
      <button class="btn m btn-primary py-4" (click)="goBack()">Назад</button>
    </div>
  </div>
  <iframe [src]="url | safe" width="100%" height="100%"></iframe>
</div>
