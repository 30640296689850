import {inject, Injectable} from "@angular/core";

import {BehaviorSubject, map, Observable, tap} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {EnvironmentApiUrlService} from "../environment-api-url.service";
import {AdminDataService} from "../admin/admin.data.service";
import {Station, StationInfo, StationLight} from "../../model/station.model";
import {ConstructorProject, Project} from "../../model/project.model";
import {Metric} from "../../model/metric.model";
import {Address} from "../../model/address.model";
import {StationMapperService} from "./station.mapper.service";
import {StationInfoDTO} from "../../dto/station.dto";

@Injectable({
  providedIn: "root"
})
export class StationService {
  private stationsLightObserver: BehaviorSubject<StationLight[] | null | undefined> = new BehaviorSubject<StationLight[] | null | undefined>(undefined);
  stationsLightSubscriber$: Observable<StationLight[] | null | undefined> = this.stationsLightObserver.asObservable();

  private selectedStationObserver: BehaviorSubject<Station | null | undefined> = new BehaviorSubject<Station | null | undefined>(undefined);
  selectedStationSubscriber$: Observable<Station | null | undefined> = this.selectedStationObserver.asObservable();

  mapper = inject(StationMapperService)

  constructor(private http: HttpClient,
              private envUrl: EnvironmentApiUrlService,
              private adminService: AdminDataService) {
  }

  get stationId(): string | null{
    return this.selectedStationObserver.value?.id || null
  }

  getUserStations(): Observable<StationLight[]> {
    return this.http.get<StationLight[]>(this.envUrl.apiUrlV1 + `/stations/basic-info`).pipe(tap(st => {
      this.stationsLightObserver.next(st);
    }));
  }

  getSelectedStation(id: string): Observable<Station> {
    return this.http.get<Station>(this.envUrl.apiUrlV1 + `/stations/${id}`).pipe(tap(st => {
      this.selectedStationObserver.next(st);
    }));
  }

  getStationInfo(id: string): Observable<Station> {
    return this.http.get<StationInfoDTO>(this.envUrl.apiUrlV1 + `/stations/${id}/info`)
      .pipe(map(res => this.mapper.mapStationInfoDTOToStation(res)))
      // .pipe(tap(st => { }));
  }

  doNextStation(value: Station | null) {
    this.selectedStationObserver.next(value);
  }

  //todo ручка добавления станции
  // bindStation(value: Station): Observable<Station> {
  //   return Observable.prototype
  // }

  //todo getSpecifitation
  getProject(id: string): Observable<Project> {
    return Observable.prototype;
  }


  getSensorsByGroup(id: string, group: string): Observable<Metric[]> {
    return this.http.get<Metric[]>(this.envUrl.apiUrlV1 + `/stations/${id}/metrics`, {
      params: {
        group: group
      }
    });
  }

  getDevicesByGroup(id: string, group: string): Observable<Metric[]> {
    return this.http.get<Metric[]>(this.envUrl.apiUrlV1 + `/stations/${id}/devices`, {
      params: {
        group: group
      }
    });
  }

  createStationWithConstructor(form: any, project: ConstructorProject) {
    const station = {
      serialNumber: form.project,
      name: form.name,
      timestamp: form.timestamp,
      customer: form.ownerName,
      specifications: project.template
    };
    return this.http.post(this.envUrl.apiUrlV1 + `/stations/pro`, station).pipe(tap((st: any) => {
      this.adminService.bindStationToOwner(st.id, form.ownerId).subscribe();
    }));
  }

  changeStationInfo(id: string, name: string, address: Address): Observable<StationInfo> {
    return this.http.put<StationInfo>(this.envUrl.apiUrlV1 + `/stations/${id}`, {
      name: name,
      address: address
    });
  }
}
