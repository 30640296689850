import {Injectable} from '@angular/core';
import {SpecificationDTO, StationInfoDTO} from "../../dto/station.dto";
import {Address, Specification, Station} from "@cloud/shared";

@Injectable({
  providedIn: 'root'
})
export class StationMapperService {

  mapStationToStationInfoDTO(): StationInfoDTO {
    return {
      address: {} as Address,
      owners: [],
      customer: null, id: "", name: "", project: null, specifications: [], timestamp: 0, type: ""}
  }

  mapStationInfoDTOToStation(res: StationInfoDTO): Station {
    return {
      id: res.id,
      project: res.project,
      timestamp: res.timestamp,
      customer: res.customer,
      type: res.type,
      address: res.address,
      owners: res.owners,
      name: res.name,
      stationDetails: [],
      specifications: this.mapSpecifications(res.specifications),
    }
  }

  private mapSpecifications(specifications: SpecificationDTO[]): Specification[] {
    return specifications.map(spec => {
      const sp: Specification = {
        metrics: null,
        brand: spec.brand,
        coupledSpecifications: spec.coupled_specifications ? this.mapSpecifications(spec.coupled_specifications) : [],
        details: [],
        id: spec.id,
        localName: spec.local_name,
        name: spec.name,
        specifications: spec.specifications ? this.mapSpecifications(spec.specifications) : [],
        timestamp: spec.timestamp,
        type: spec.type
      }
      return sp
    })
  }

}
