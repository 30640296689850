import { animate, keyframes, style, transition, trigger } from "@angular/animations";

export const specificationMenuAnimation = trigger("specMenu", [
  transition("void => *", [
    animate("600ms ease-out",
      keyframes([
        style({ border: "none", opacity: 0.1, width: 0, height: 0, transform: "scaleX(0)", "white-space": "nowrap" }),
        style({ opacity: 0.2, transform: "scaleX(0.9)" }),
        style({ opacity: 1, width: "*", height: "*", transform: "scaleX(1)", "white-space": "nowrap" })
      ])
    )
  ]),
  transition("* => void", [
    animate("600ms ease-out",
      keyframes([
        style({ border: "none", opacity: 0.1, width: 0, height: 0, transform: "scaleX(0)", "white-space": "nowrap" }),
        style({ opacity: 0.2, transform: "scaleX(0.9)" }),
        style({ opacity: 1, width: "*", height: "*", transform: "scaleX(1)", "white-space": "nowrap" })
      ])
    )
  ])
]);
