import { forwardRef, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { WidgetLight } from "@cloud/shared";
import { DashboardDataService } from "./dashboard.data.service";
import { DashboardModel } from "../../model/dashboard.model";

@Injectable({
  providedIn: "root",
  useClass: forwardRef(() => DashboardDataService)
})

export abstract class DashboardService {

  // abstract readonly cards: BehaviorSubject<LayoutItem[]>;
  // abstract readonly cards$: Observable<LayoutItem[]>;

  abstract getDashboard(user_id: string): Observable<DashboardModel[]>;

  abstract addNewDashboardItem(dashboard: DashboardModel[], widget: WidgetLight): Observable<DashboardModel[]>;

  abstract updateDashboard(user_id: string, dashboard: DashboardModel[]): Observable<DashboardModel[]>;

  // abstract getDashboardItem(item_id: string): Observable<LayoutItem>

}
