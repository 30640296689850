import { ReportTypeLegacy } from "../model/report-interface.model";

export const ReportTypes = {
  OPERATION: {
    VOLUME: { QUERY: "volume", TITLE: "Статистика по расходу" } as ReportTypeLegacy,
    DAILY_VOLUME: {
      QUERY: "volumes",
      TITLE: "Статистика по суточным расходам за период",
      TYPE: "daily"
    } as ReportTypeLegacy,
    HOURLY_VOLUME: {
      QUERY: "volumes",
      TITLE: "Статистика по часовым расходам за период",
      TYPE: "hourly"
    } as ReportTypeLegacy,
    TEMPERATURE: { QUERY: "temperature", TITLE: "Температурный режим" } as ReportTypeLegacy
  },
  EVENTS: {
    METRIC: { QUERY: "metrics", TITLE: "Отчет по метрикам за период" } as ReportTypeLegacy
  },
  ERRORS: {
    TEMPERATURE: { QUERY: "temperature-errors", TITLE: "Отчет о температурных авариях и отклонениях" } as ReportTypeLegacy,
    PRESSURE: { QUERY: "pressure-errors", TITLE: "Отчет по авариям давления" } as ReportTypeLegacy,
    VOLUME: { QUERY: "volume-errors", TITLE: "Отчет по авариям расхода" } as ReportTypeLegacy,
    CURRENT: { QUERY: "current-errors", TITLE: "Отчет по авариям тока" } as ReportTypeLegacy,
    DISCRETE_LEVEL: { QUERY: "discrete-level-errors", TITLE: "Отчет по авариям дискретных уровней" } as ReportTypeLegacy,
    ANALOG_LEVEL: { QUERY: "analogue-level-errors", TITLE: "Отчет по авариям аналоговых уровней" } as ReportTypeLegacy
  }
};

export enum SensorsType {
  VOLUME = "volume",  //Расход
  PRESSURE = "pressure", //Давление
  TEMPERATURE = "temperature", //Температура
  COUNTER = "counter", //Счетчик
  CURRENT = "current", //Ток
  ANALOGUE = "analogue", // аналоговый уровень
  DISCRETE = "discrete", // цифровой уровень
  AAMP = "aamp",
  BLOWER = "bwr"
}

export enum DiscreteFrequency {
  DAILY = "daily",
  HOURLY = "hourly"
}

