import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {Error404Component} from "./ui/components/error404/error404.component";
import {Error403Component} from "./ui/components/error403/error403.component";
import {PolicyComponent} from "./ui/components/policy/policy.component";
import {AuthGuard} from "@cloud/shared";
import {MainPageLoaderComponent} from "./ui/components/main-page-loader/main-page-loader.component";

const routes: Routes = [
  {path: '', redirectTo: 'loading', pathMatch: 'full'},
  {path: 'loading', component: MainPageLoaderComponent},
  //todo deprecated
  // {path: 'passport', loadChildren: () => import('./auth/auth.module').then(x => x.AuthModule)},
  {
    path: 'index', canActivate: [AuthGuard], loadChildren: () => import('./main/main.module').then(x => x.MainModule)
  },
  {path: 'policy', component: PolicyComponent},
  {path: '403', component: Error403Component},
  {path: '**', component: Error404Component},
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
