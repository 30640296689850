import { forwardRef, Injectable } from "@angular/core";
import { WidgetDataService } from "./widget.data.service";
import { Observable } from "rxjs";
import { Chart, ChartConfiguration } from "chart.js";
import { Widget } from "../../model/widget.model";
import { WidgetTypes } from "../../enums/widget.enum";

@Injectable({
  providedIn: "root",
  useClass: forwardRef(() => WidgetDataService)
})
export abstract class WidgetService {

  abstract getDemoWidget(type: WidgetTypes): ChartConfiguration | null

  abstract getFlowMeterIrregularityWidget(id: string): Observable<ChartConfiguration>
  abstract updateIrregularityWidgetDataset(chart: Chart, id?: string): Observable<any>

  abstract getFlowMeterFlowrateWidget(id: string): Observable<ChartConfiguration>
  abstract updateFlowMeterFlowrateWidgetDataset(chart: Chart, id?: string): Observable<any>

  abstract getFlowmeterVolumeWidget(id: string): Observable<ChartConfiguration>
  abstract updateFlowmeterVolumeWidgetDataset(chart: Chart, id?: string): Observable<any>

  abstract getTemperatureWidget(id: string): Observable<ChartConfiguration>
  abstract updateTemperatureWidgetDataset(chart: Chart, id?: string): Observable<any>

  abstract getBlowerWidget(id: string): Observable<[ChartConfiguration, Widget]>
  abstract updateBlowerWidgetDataset(chart: Chart, id?: string): Observable<any>

  abstract getAAMPPumpWidget(id: string): Observable<ChartConfiguration>
  abstract updateAAMPPumpWidgetDataset(chart: Chart, id?: string): Observable<any>
}
