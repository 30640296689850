import { animate, keyframes, state, style, transition, trigger } from "@angular/animations";

export const metricsAnimation = trigger("metrics", [
  transition("void => *", [
    animate("600ms ease-out",
      keyframes([
        style({ opacity: 0, height: 0, transform: "scaleY(0)", "overflow-y": "hidden" }),
        style({ opacity: 0, height: 0.5, transform: "scaleY(1.1)" }),
        style({ opacity: 1, height: "*", transform: "scaleY(1)" })

      ])
    )
  ]),
  transition("* => void", [
    animate("300ms ease-out",
      keyframes([
        style({ opacity: 1, height: "*", transform: "scaleY(1)", "overflow-y": "hidden" }),
        style({ opacity: 0, height: 0.5, transform: "scaleY(0.5)" }),
        style({ opacity: 0, height: 0, transform: "scaleY(0)" })

      ])
    )
  ])
]);

export const detailsAnimation = trigger("details", [
  transition("void => *", [
    animate("700ms ease-in-out",
      keyframes([
        style({ opacity: 0, height: 0, transform: "scaleY(0)" }),
        style({ opacity: 0.5, height: 0.5, transform: "scaleY(0.5)" }),
        style({ opacity: 1, height: "*", transform: "scaleY(1)" })
      ])
    )
  ]),
  transition("* => void", [
    animate("700ms ease-in-out",
      keyframes([
        style({ opacity: 0, height: 0, transform: "scaleY(0)" }),
        style({ opacity: 0.5, height: 0.5, transform: "scaleY(0.5)" }),
        style({ opacity: 1, height: "*", transform: "scaleY(1)" })
      ])
    )
  ])
]);


export const dropdownAnimation = trigger("dropdownAnimation", [
  transition(":enter", [
    style({
      transform: "translate(0, -40px)",
      opacity: 0,
      visibility: "hidden"
    }),
    animate(
      "400ms ease-in-out",
      style({ transform: "translate(0, 0)", opacity: 1, visibility: "visible" })
    )
  ]),
  transition(":leave", [
    style({ transform: "translate(0, 0)", opacity: 1, visibility: "visible" }),
    animate(
      "400ms ease-in-out",
      style({
        transform: "translate(0, -40px)",
        opacity: 0,
        visibility: "hidden"
      })
    )
  ])
]);
