import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { filter, map, Observable, Subject, tap } from "rxjs";
import { AuthService } from "../services/auth.service";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard {


  private isAuthenticated = false;

  private loader$ = new Subject<boolean>();
  public loader = false;

  constructor(private auth: AuthService) {
    this.loader$.subscribe(loader => {
      this.loader = loader;
    });
    this.auth.isAuthenticated$.subscribe(i => this.isAuthenticated = i);
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> {
    this.loader$.next(true);
    return this.auth.isDoneLoading$
      .pipe(filter(isDone => isDone))
      .pipe(tap(_ => this.isAuthenticated || this.auth.login()))
      .pipe(map(_ => this.isAuthenticated))
      .pipe(tap(() => this.loader$.next(false)));
  }
}
