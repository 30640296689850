import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-policy',
  templateUrl: './policy.component.html',
  styleUrls: ['./policy.component.scss']
})
export class PolicyComponent implements OnInit {
  url = ''

  constructor(private activateRoute: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.activateRoute.queryParams.subscribe((params) => {
      //todo сделать новую политику для ПРО
       if (params['pro'])  {
         console.log(params)
         this.url = 'https://wallbox.ru/wallpapers/main/201628/2c416f658ca0881.jpg'
       } else { this.url = 'https://storage.yandexcloud.net/altabio/policy.pdf'}
    })
  }

  load() {
    const link = document.createElement('a')
    link.href = this.url
    link.download = this.url.substring((this.url.lastIndexOf("/") + 1), this.url.length);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  goBack(){
    history.back()
  }

}


