import { Injectable } from "@angular/core";
import { DashboardService } from "@cloud/shared";
import { Observable, of } from "rxjs";
import { WidgetLight } from "@cloud/shared";
import { DashboardAdapterService } from "./dashboard.adapter.service";
import { DashboardModel } from "@cloud/shared";
import { v4 as uuidv4 } from "uuid";
import { KtdGridLayoutItem } from "@katoid/angular-grid-layout";

@Injectable({
  providedIn: "root"
})

export class DashboardDataService implements DashboardService {
  // readonly cards: BehaviorSubject<LayoutItem[]>;
  // readonly cards$: Observable<LayoutItem[]>;

  constructor(private api: DashboardAdapterService) {
  }

  getDashboard(id: string): Observable<DashboardModel[]> {
    return this.api.getDashboardByUserId(id);
  }

  addNewDashboardItem(dashboard: DashboardModel[], widget: WidgetLight): Observable<DashboardModel[]> {
    const id = uuidv4();
    const newLayoutItem: KtdGridLayoutItem = {
      id, x: 0, y: 0, w: 6, h: 4
    };
    return of([
      {
        ...newLayoutItem,
        widget
      },
      ...dashboard
    ]);

  }

  updateDashboard(user_id: string, dashboard: DashboardModel[]): Observable<DashboardModel[]> {
    return this.api.putDashboardChanges(user_id, dashboard);
  }

}
