export enum UnitsEnum {
  NONE = "NONE",
  CUBIC_METER_PER_HOUR = "м³/ч",
  CUBIC_METER_PER_DAY = "м³/сут",
  KILOWATT = "кВт",
  KILOWATT_HOUR = "кВт*ч",
  C = "°C",
  CUBIC_METER = "м³",
  V = "В",
  MA = "мА",
  MBAR = "мбар",
  SEC = "с",
  LITER_PER_HOUR = "л/ч",
  METER = "м",
  MV = "мВ",
  MILLIGRAM_PER_LITER = "мг/л",
  MILLISIEMENS_PER_CENTIMETER = "мСм/см",
  PSU = "PSU",
  NTU = "NTU",
  METER_PER_SECOND = "м/с",
  COUNTS_PER_HOUR = "1/ч",
  KILOGRAM_PER_HOUR = "кг/ч",
  PERCENT = "%",
  WATT_PER_METER = "Вт/м",
  HOUR = "ч"
}