import {Directive, Input, TemplateRef, ViewContainerRef} from '@angular/core';


interface LetContext<T> {
  ngLet: T;
}
@Directive({
  selector: '[ngLet]'
})
export class LetDirective {
  private _context: LetContext<any> = {ngLet: null};
  constructor(_viewContainer: ViewContainerRef, _templateRef: TemplateRef<LetContext<any>>) {
    _viewContainer.createEmbeddedView(_templateRef, this._context);
  }

  @Input()
  set ngLet(value: any) {
    this._context.ngLet = value;
  }
}
