<div class="menu-item metrics-container" @specMenu>
  <div class="list">
    <button type="button" *ngFor="let item of specs" class="btn none-border list-item" (click)="renderColumn(item); clg(item)"
            [ngClass]="{active: item == activatedSpec}">
      {{item.localName || item.name}}
      <i *ngIf="item.specifications && item.specifications.length > 0" class='bx bx-chevron-right'></i>
    </button>
  </div>
<!--  DEPRECATED-->
<!--  <ng-container #metrics>-->
<!--  </ng-container>-->
</div>
