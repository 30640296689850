import { ComponentRef, Injectable, ViewContainerRef } from "@angular/core";
import { Metric, Specification } from "@cloud/shared";
import { BehaviorSubject, Observable, Subject, Subscription } from "rxjs";
import { ColumnComponent } from "../../../ui/components/specification-menu/column/column.component";
import { SpecificationMenuService } from "./specification-menu.service";

@Injectable({
  providedIn: "root"
})
export class SpecificationMenuDataService implements SpecificationMenuService {

  private metricObserver: Subject<Metric> = new Subject<Metric>();
  private metricsArrayObserver: BehaviorSubject<Metric[] | null> = new BehaviorSubject<Metric[] | null>(null);
  private specificationObserver: BehaviorSubject<Specification | null> = new BehaviorSubject<Specification | null>(null);
  private scrollboxObserver: Subject<boolean> = new Subject<boolean>();

  metricsSubscriber$: Observable<Metric> = this.metricObserver.asObservable();
  metricsArraySubscriber$: Observable<Metric[] | null> = this.metricsArrayObserver.asObservable();
  specificationSubscriber$: Observable<Specification | null> = this.specificationObserver.asObservable();
  scrollboxSubscriber$: Observable<boolean> = this.scrollboxObserver.asObservable();


  private sub: Subscription;

  constructor() {
    this.sub = this.toggleMetricInArray();
  }

  renderColumn(item: Specification[] | null,
               level: number, container: ViewContainerRef,
               columns: Array<ComponentRef<any>>): ComponentRef<any>[] {
    columns = this.filterColumns(level, columns);

    for (let i = level; i < container.length; i++) {
      container.remove(i);
      columns[i]?.destroy();
    }

    if (item && item.length > 0) {
      const component = container.createComponent(ColumnComponent);
      component.instance.specs = item;
      component.instance.container = container;
      component.instance.columns = columns;
      component.instance.level = level;
      columns.push(component);
    }
    return columns;
  }

  private filterColumns(level: number, columns: Array<ComponentRef<any>>): Array<ComponentRef<any>> {
    return columns.filter(column => {
      if (column.instance.level >= level) column.destroy();
      return column.instance.level < level;
    });
  }

  emitMetric(value: Metric): void {
    this.metricObserver.next(value);
  }

  emitMetricArray(value: Metric[] | null): void {
    this.metricsArrayObserver.next(value);
  }

  emitSpecification(value: Specification): void {
    this.specificationObserver.next(value);
  }

  emitColumns(value: boolean) {
    this.scrollboxObserver.next(value);
  }

  private toggleMetricInArray(): Subscription {
    return this.metricsSubscriber$.subscribe(metric => {
      try {
        let metrics = this.metricsArrayObserver.value;
        metrics!.includes(metric) ? metrics = metrics!.filter(value => value !== metric) : metrics!.push(metric);
        this.emitMetricArray(metrics);
      } catch (error) {
        this.emitMetricArray([metric]);
      }
    });
  }

}
