import {Injectable} from '@angular/core';
import {Router, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthService} from "../services/auth.service";
import { RolesEnum } from "../enums/roles.enum";

@Injectable({
  providedIn: 'root'
})
export class SystemCredentialsGuard {

  constructor(private auth: AuthService,
              private router: Router) {
  }

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.checkCredentials();
  }

  checkCredentials(): boolean {
    if (this.auth.roles.includes(RolesEnum.AG_MANAGER || RolesEnum.AG_TECHNOLOGIST)) return true
    this.router.navigate([this.router.url]).then(() => console.error('Invalid Credentials'))
    return false
  }

}
