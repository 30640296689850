import { Injectable } from "@angular/core";
import { ChartTypes, Widget } from "@cloud/shared";
import {
  BubbleDataPoint,
  Chart,
  ChartConfiguration,
  ChartDataset,
  ChartTypeRegistry,
  ScatterDataPoint
} from "chart.js";
import { customTooltips } from "./plugins.service";
import { WidgetConstructorAbstractService } from "./widget-constructor.abstract.service";

@Injectable({
  providedIn: "root"
})
export class BlowerService implements WidgetConstructorAbstractService {

  createDemoWidgetConfig(): ChartConfiguration<keyof ChartTypeRegistry, (number | ScatterDataPoint | BubbleDataPoint | null)[], unknown> {
    const widget: Widget = {
      id: "device_id", //blower
      details: {
        current_max: 1000,
        current_min: 100,
        overpressure_max: 2000,
        underpressure_max: 100,
        legend: "полное наименование устройства"
      },
      data: [
        { label: "STATE", value: 1 },
        { label: "CURRENT_CONSUMPTION_A", value: 572 },
        { label: "CURRENT_CONSUMPTION_B", value: 2000 },
        { label: "CURRENT_CONSUMPTION_C", value: 1234 },
        { label: "OVERPRESSURE", value: 1234 },
        { label: "UNDERPRESSURE", value: 1234 }
      ]
    };
    return this.createWidgetConfig(widget);
  }

  createWidgetConfig(widget: Widget): ChartConfiguration {
    return {
      type: ChartTypes.RADAR,
      options: this.createWidgetOptions(widget),
      data: this.createWidgetDataset(widget)
    };
  };

  private createWidgetOptions(widget: Widget): ChartConfiguration["options"] {
    return {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        // filler: {
        //   propagate: false
        // },
        title: {
          display: false
        },
        legend: {
          display: true
        },
        tooltip: {
          enabled: false,
          mode: "index",
          position: "nearest",
          external: customTooltips
          //   callbacks: {
          //     title: (context: any) => {
          //       return new Date(context[0].parsed.x).toLocaleString();
          //     }
          //   }
        }
      },
      interaction: {
        intersect: false
      },
      scales: {
        r: {
          beginAtZero: true,
          suggestedMax: this.calculateBlowerMax(widget),
          ticks: {
            stepSize: this.calculateBlowerStepSize(widget)
          }
        }
      }
    };
  }

  private createWidgetDataset(widget: Widget): ChartDataset<any> {
    widget.data = widget.data.map(d => {
      if (d.label === "CURRENT_CONSUMPTION_A") d.label = "фаза А, мА";
      if (d.label === "CURRENT_CONSUMPTION_B") d.label = "фаза B, мА";
      if (d.label === "CURRENT_CONSUMPTION_C") d.label = "фаза C, мА";
      return d;
    });
    const correctData = widget.data.filter(point => point.label.includes("фаза"));
    const labels = correctData.map(point => point.label);
    return {
      labels: labels,
      datasets: [
        {
          label: widget.details.legend,
          data: correctData.map(point => point.value),
          backgroundColor: "rgba(0,123,193,0.2)",
          borderColor: "rgba(0,123,193,1)",
          pointBackgroundColor: "transparent",
          pointBorderColor: "transparent",
          pointHoverBackgroundColor: "rgba(0,123,193,1)",
          pointHoverBorderColor: "rgba(0,123,193,1)"
        }
      ]
    };
  };

  private calculateBlowerMax(widget: Widget): number {
    const max = Math.max(...widget.data.map(point => point.value));
    switch (true) {
      case max < widget.details.current_max!:
        return widget.details.current_max!;
      case max < 10:
        return max % 1;
      case max < 50:
        return max % 5;
      default:
        return max;
    }
  }

  private calculateBlowerStepSize(widget: Widget): number | undefined {
    const max = Math.max(...widget.data.map(point => point.value));
    switch (true) {
      case max < 10:
        return 1.0;
      case max < 50:
        return 5.0;
      default:
        return undefined;
    }
  }

  updateWidgetDataset(chart: Chart, widget: Widget) {
    chart.data.datasets.forEach((dataset: any) => {
      dataset.data = chart.data.labels!.map((value, index) => {
        const correctData = widget.data.filter(point => point.label.includes("фаза"));
        return correctData.map(point => point.value);
      });
    });
    chart.update();
  }

}
