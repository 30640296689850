import { ComponentRef, forwardRef, Injectable, ViewContainerRef } from "@angular/core";
import { Metric, Specification } from "@cloud/shared";
import { Observable, Subject } from "rxjs";
import { SpecificationMenuDataService } from "./specification-menu.data.service";


@Injectable({
  providedIn: "root",
  useClass: forwardRef(() => SpecificationMenuDataService)
})
export abstract class SpecificationMenuService {

  abstract readonly metricsSubscriber$: Observable<Metric>;
  abstract readonly metricsArraySubscriber$: Observable<Metric[] | null>;
  abstract readonly specificationSubscriber$: Observable<Specification | null>;
  abstract readonly scrollboxSubscriber$: Observable<boolean>;

  abstract renderColumn(item: Specification[] | null, level: number, container: ViewContainerRef, columns: Array<ComponentRef<any>>): ComponentRef<any>[]

  abstract emitMetric(value: Metric | null): void

  abstract emitMetricArray(value: Metric[] | null): void

  abstract emitSpecification(value: Specification): void

  abstract emitColumns(value: boolean): void


}
