import { Injectable } from "@angular/core";
import { ChartTypes, Widget } from "@cloud/shared";
import {
  BubbleDataPoint,
  Chart,
  ChartConfiguration,
  ChartDataset,
  ChartTypeRegistry,
  ScatterDataPoint
} from "chart.js";
import { customTooltips } from "./plugins.service";
import { WidgetConstructorAbstractService } from "./widget-constructor.abstract.service";

@Injectable({
  providedIn: "root"
})
export class AampPumpService implements WidgetConstructorAbstractService {

  createDemoWidgetConfig(): ChartConfiguration<keyof ChartTypeRegistry, (number | ScatterDataPoint | BubbleDataPoint | null)[], unknown> {
    const widget: Widget = {
      id: "device_id", //blower
      details: {
        legend: "полное наименование устройства"
      },
      data: [
        {
          label: "Имя насоса",
          value: 1400,
          sequence_number: "1",
          max: 200
        },
        {
          label: "Имя насоса",
          value: 1000,
          sequence_number: "2",
          max: 1000
        },
        {
          label: "Имя насоса",
          value: 2500,
          sequence_number: "3",
          max: 3000
        }

      ]
    };
    return this.createWidgetConfig(widget);
  }

  createWidgetConfig(widget: Widget): ChartConfiguration {
    return {
      type: ChartTypes.POLAR_AREA,
      data: this.createWidgetDataset(widget),
      options: this.createWidgetOptions(widget)
    };
  };

  private createWidgetOptions(widget: Widget): ChartConfiguration["options"] {
    return {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        tooltip: {
          enabled: false,
          mode: "index",
          position: "nearest",
          external: customTooltips
        },
        title: {
          display: false
        },
        subtitle: {
          display: true,
          text: "мА"
        },
        legend: {
          display: true
        }
      },
      interaction: {
        intersect: false
      },
      scales: {
        // ticks: {
        // display: true
        // },
        r: {
          beginAtZero: true,
          suggestedMax: Math.max(...widget.data.map(point => point.max!)),

          pointLabels: {
            display: true,
            centerPointLabels: true,
            font: {
              size: 12
            }
          }
        }
      }
    };
  }

  private createWidgetDataset(widget: Widget): ChartDataset<any> {
    return {
      labels: widget.data.map(point => point.label + " " + point.sequence_number),
      datasets: [
        {
          data: widget.data.map(point => point.value),
          backgroundColor: widget.data.map((point, index) => `rgba(0,123,193, ${0.2 + index * 0.1})`),
          // borderColor: "rgba(0,123,193,1)",
          pointBackgroundColor: "transparent",
          pointBorderColor: "transparent",
          pointHoverBackgroundColor: "rgba(0,123,193,1)",
          pointHoverBorderColor: "rgba(0,123,193,1)"
        }
      ]
    };
  };

  updateWidgetDataset(chart: Chart, widget: Widget) {
    chart.data.datasets.forEach((dataset: any) => {
      dataset.data = widget.data.map(point => point.value);
    });
    chart.update();
  }
}
