import { Injectable } from "@angular/core";
import { ChartTypes, Widget } from "@cloud/shared";
import {
  BubbleDataPoint,
  Chart,
  ChartConfiguration,
  ChartDataset,
  ChartTypeRegistry,
  ScatterDataPoint
} from "chart.js";
import { ru } from "date-fns/locale";
import { customTooltips } from "./plugins.service";
import { WidgetConstructorAbstractService } from "./widget-constructor.abstract.service";

@Injectable({
  providedIn: "root"
})
export class TemperatureService implements WidgetConstructorAbstractService {


  createDemoWidgetConfig(): ChartConfiguration<keyof ChartTypeRegistry, (number | ScatterDataPoint | BubbleDataPoint | null)[], unknown> {
    const widget: Widget = {
      id: "device_id",
      details: { rate: 0, legend: "полное наименование устройства" },
      data: [
        { label: "05.03", value: 10 },
        { label: "06.03", value: 20 },
        { label: "07.03", value: 30 },
        { label: "08.03", value: 40 },
        { label: "09.03", value: 50 },
        { label: "10.03", value: 60 },
        { label: "11.03", value: 70 }
      ]
    };
    return this.createWidgetConfig(widget);
  }

  createWidgetConfig(widget: Widget): ChartConfiguration {
    return {
      type: ChartTypes.LINE,
      data: this.createWidgetDataset(widget),
      options: this.createWidgetOptions(widget)
    };
  };

  private createWidgetOptions(widget: Widget): ChartConfiguration["options"] {
    return {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        filler: {
          propagate: false
        },
        title: {
          display: false
        },
        legend: {
          display: true
          // text: widget.details.legend
        },
        tooltip: {
          callbacks: {
            title: (context: any) => {
              return new Date(context[0].parsed.x).toLocaleString();
            }
          },
          enabled: false,
          mode: "index",
          position: "nearest",
          external: customTooltips
        }
      },
      interaction: {
        intersect: false
      },

      scales: {
        x: {
          type: "time",
          time: {
            unit: "day",
            displayFormats: {
              day: "dd.MM"
            }
          },
          ticks: {
            autoSkip: true,
            maxTicksLimit: 10
          },
          adapters: {
            date: {
              locale: ru
            }
          }
        }
      }
    };
  }

  private createWidgetDataset(widget: Widget): ChartDataset<any> {
    // const labels = widget.data.map(point => point.label);
    return {
      // labels: labels,
      datasets: [
        {
          label: widget.details.legend,
          fill: true,
          tension: 0.25,
          data: widget.data.map(point => {
            const x = Number(point.label);
            const y = point.value as number;
            return { x: x, y: y };
          }),
          backgroundColor: "rgba(0,123,193,0.2)",
          borderColor: "rgba(0,123,193,1)",

          pointBackgroundColor: "transparent",
          pointBorderColor: "transparent",
          pointHoverBackgroundColor: "rgba(0,123,193,1)",
          pointHoverBorderColor: "rgba(0,123,193,1)"
        }
      ]
    };
  }

  updateWidgetDataset(chart: Chart, widget: Widget) {
    chart.data.datasets.forEach((dataset: any) => {
      dataset.data = widget.data.map(point => {
        const x = Number(point.label);
        const y = point.value;
        return { x: x, y: y };
      });
    });
    chart.update();
  }

  get defaultWidgetType(): keyof ChartTypeRegistry {
    throw new Error("Method not implemented.");
  }

  get defaultWidgetData(): any {
    throw new Error("Method not implemented.");
  }

  get defaultWidgetOptions(): ChartConfiguration["options"] {
    throw new Error("Method not implemented.");
  }

}
