import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root"
})
export class EnvironmentApiUrlService {
  public production: boolean = environment.production;
  public apiUrlV1: string = environment.apiUrl + "/api/v1";
  public apuUrlV2: string = environment.apiUrl + "/api/v2"
  public clientUrl: string = window.location.origin;
}
