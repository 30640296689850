import { Injectable, forwardRef } from '@angular/core';
import { Observable } from 'rxjs';
import { ErrorCode, ErrorContent, PageLite } from "@cloud/shared";
import { ErrorsDataService } from './errors.data.service';

@Injectable({
  providedIn: 'root',
  useClass: forwardRef(() => ErrorsDataService)
})
export abstract class ErrorsService {

  abstract readonly errors$: Observable<PageLite>;

  abstract getStationErrors(id: string, count: number, offset: number, filters?: Map<string, any>): Observable<PageLite>;

  abstract getErrorCodes(): Observable<ErrorCode[]>;

  abstract editErrorCode(errorCode: ErrorCode): Observable<ErrorCode>;

  abstract createNewErrorCode(errorCode: ErrorCode): Observable<ErrorCode>;

  abstract setSelectedErrors(errors: ErrorContent[]): Observable<ErrorContent[]>;

}
