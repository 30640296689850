import {Injectable} from "@angular/core";
import {DashboardAdapterService} from "./dashboard.adapter.service";
import {KtdGridLayout} from "@katoid/angular-grid-layout";
import {delay, Observable, of} from "rxjs";
import {DashboardModel, WidgetTypes} from "@cloud/shared";

@Injectable({
  providedIn: "root"
})

export class DashboardServiceMock implements DashboardAdapterService {
  getDashboardByUserId(id: string): Observable<DashboardModel[]> {

    const layout: KtdGridLayout = [
      { id: "a", x: 0, y: 0, w: 6, h: 4 },
      { id: "b", x: 6, y: 0, w: 6, h: 4 },
      { id: "c", x: 0, y: 4, w: 6, h: 4 },
      { id: "d", x: 6, y: 4, w: 6, h: 4 },

      { id: "e", x: 0, y: 8, w: 6, h: 4 },
      { id: "f", x: 6, y: 8, w: 6, h: 4 },
      { id: "g", x: 0, y: 12, w: 6, h: 4 },
    ];

    const dashboard: DashboardModel[] = [
      {...layout[0], widget: {type: WidgetTypes.LOGS, metadata: null}},
      {...layout[1], widget: {type: WidgetTypes.AAMP_PUMPS, metadata: {selected_sensor: '965c8a1d-ab8e-4f89-8103-5640b995e8c4'}}},
      {...layout[2], widget: {type: WidgetTypes.BLOWER, metadata: {selected_sensor: '59631a3a-0617-4840-a4c1-c37a8b66c3a4'}}},
      {...layout[3], widget: {type: WidgetTypes.FLOW_DAILY_IRREGULARITY, metadata: {selected_sensor: '3d931788-81fc-4e82-ad1f-adce729fa3af'}}},
      {...layout[4], widget: {type: WidgetTypes.FLOW_DAILY_FLOWRATE, metadata: {selected_sensor: '3d931788-81fc-4e82-ad1f-adce729fa3af'}}},
      {...layout[5], widget: {type: WidgetTypes.FLOW_VOLUME, metadata: {selected_sensor: '3d931788-81fc-4e82-ad1f-adce729fa3af'}}},
      {...layout[6], widget: {type: WidgetTypes.TEMPERATURE, metadata: {selected_sensor: '0459a7ae-917e-4865-ba32-da24909476a5'}}},
    ]

    // const dashboard: DashboardModel[] = layout.map(item => {
    //   const values = Object.keys(WidgetTypes);
    //   const enumKey: string = values[Math.floor(Math.random() * values.length)];
    //   return {
    //     // @ts-ignore
    //     // ...item, widget: {type: WidgetTypes[enumKey], metadata: {selected_sensor: 'f54f5b1c-e69f-4881-b44e-250d808b7fdb'}}
    //     ...item, widget: {type: WidgetTypes.FLOW_DAILY_IRREGULARITY, metadata: {selected_sensor: '3d931788-81fc-4e82-ad1f-adce729fa3af'}}
    //     // ...item, widget: {type: WidgetTypes[enumKey], metadata: {selected_sensor: '3d931788-81fc-4e82-ad1f-adce729fa3af'}}
    //   };
    // });

    return of(dashboard).pipe(delay(1000));
  }

  putDashboardChanges(user_id: string, dashboard: DashboardModel[]): Observable<DashboardModel[]> {
    return of(dashboard);
  }

  // getGridLayoutItemByItemId(id: string): Observable<LayoutItem> {
  //   const values = Object.keys(WidgetTypes);
  //   const enumKey: string = values[Math.floor(Math.random() * values.length)];
  //   // @ts-ignore
  //   const widget = WidgetTypes[enumKey];
  //   // const item: LayoutItem = { id: id, type: widget };
  //   const item: LayoutItem = { id: id, type: WidgetTypes.FLOW_DAILY_IRREGULARITY};
  //   return of(item);
  // }

}
