import { Injectable } from "@angular/core";
import { BehaviorSubject, catchError, filter, map, mapTo, Observable, of, ReplaySubject, Subject, tap } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { EnvironmentApiUrlService } from "../services/environment-api-url.service";
import { AuthConfig } from "angular-oauth2-oidc";

@Injectable({
  providedIn: "root"
})
export class ConfigurationService {
  private configuration = {};
  public configurationReceived$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)

  constructor(private httpClient: HttpClient,
              private envUrl: EnvironmentApiUrlService) {
  }

  load() {
    let configUrl;
    this.envUrl.production ? configUrl = "/assets/config/app.config.prod.json" : configUrl = "/assets/config/app.config.json";
    return this.httpClient.get<AuthConfig>(configUrl)
      .pipe(
        tap((configuration: any) => {
          this.configuration = configuration;
          this.configurationReceived$.next(true)
        }),
        map(() => undefined)
      );
  }

  getValue(key: string, defaultValue?: any): any {
    // @ts-ignore
    return this.configuration || defaultValue;
  }
}
