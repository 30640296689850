import {AuthConfig} from "angular-oauth2-oidc";

export const OAuthConfig: AuthConfig = {

  issuer: 'https://dev.alta-cloud.ru/auth/realms/altacloud-dev',

  redirectUri: window.location.origin + '/index',

  postLogoutRedirectUri: window.location.origin,

  clearHashAfterLogin: true,

  clientId: 'altacloud-client-dev',

  responseType: 'code',

  showDebugInformation: true
}

