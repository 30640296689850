import { forwardRef, Injectable } from "@angular/core";
import { NotificationsDataService } from "./notifications.data.service";
import { Observable } from "rxjs";
import { NotificationModel } from "../../model/notification.model";

@Injectable({
  providedIn: 'root',
  useClass: forwardRef(() => NotificationsDataService)
})
export abstract class NotificationsService {

  abstract getUserNotifications(id: string): Observable<NotificationModel[]>

  abstract editUserNotifications(id: string, formValue: NotificationModel[]): Observable<NotificationModel[]>
}
