import {AuthConfig} from "angular-oauth2-oidc";

export const oAuthConfigProd: AuthConfig = {
  issuer: 'https://alta-cloud.ru/auth/realms/altacloud',

  redirectUri: window.location.origin + '/index',

  postLogoutRedirectUri: window.location.origin,

  clearHashAfterLogin: true,

  clientId: 'altacloud-client',

  responseType: 'code',

  showDebugInformation: false
};
