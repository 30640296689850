interface EnvironmentConfig {
  production: boolean
  apiUrl: string
  debug: boolean
}

export const environment: EnvironmentConfig = {
  // @ts-ignore
  production: window["env"]["production"] || true,
  // @ts-ignore
  apiUrl: window["env"]["apiUrl"] || "default",
// @ts-ignore
  debug: window["env"]["debug"] || false
};
