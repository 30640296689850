import {animate, state, style, transition, trigger} from "@angular/animations";

export const popupAnimation = trigger('popup', [
  state('void', style(
    {
      opacity: 0,
      // transform: 'scale(0.8)',
      transform: 'translate(0, -80px) scale(0.8)'
    }
    )),
  transition('void <=> *', [
    animate(250)
  ])
]);
