import {animate, state, style, transition, trigger} from "@angular/animations";

export const componentInitialAnimation = trigger("componentInitial", [
  state('void', style({height: 0})),
  state('*', style({height: '*'})),
  transition('* <=> void', [
    animate('1000ms ease-in-out',
      // keyframes([
      //   style({height: 0}),
      //   style({height: '*'})
      //   ])
      )
  ])

  // transition("void => *", [
  //   style({
  //     height: 0,
  //     opacity: 0,
  //   }),
  //   animate(
  //     "1000ms ease-in-out",
  //     style({height: "100%", opacity: 1})
  //   ),
  // ]),
  // transition(":leave", [
  //   style({height: "100%", opacity: 1}),
  //   animate(
  //     "1000ms ease-in-out",
  //     style({
  //       height: 0,
  //       opacity: 0
  //     })
  //   ),
  // ]),


])


// export let routeTransitionAnimations = trigger('fade', [
//   state('void', style({ opacity: 0,  })),
//   transition('void <=> *', [
//     animate(500)
//   ])
// ]);
