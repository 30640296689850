import { Injectable } from "@angular/core";
import { WidgetDemo } from "../model/widget-demo.model";
import { WidgetTitles, WidgetTypes } from "../enums/widget.enum";
import { Observable, of } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class WidgetDemoService {

  getWidgetsDemo(): Observable<WidgetDemo[]> {
    const widgets = [
      {
        title: WidgetTitles[WidgetTypes.AAMP_PUMPS],
        description: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Aspernatur reprehenderit animi blanditiis distinctio, autem repellat et fugiat nemo porro quidem, eos rem vel. Similique odit dignissimos ad fugiat, perspiciatis dolores!",
        type: WidgetTypes.AAMP_PUMPS
      },
      {
        title: WidgetTitles[WidgetTypes.BLOWER],
        description: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Aspernatur reprehenderit animi blanditiis distinctio, autem repellat et fugiat nemo porro quidem, eos rem vel. Similique odit dignissimos ad fugiat, perspiciatis dolores!",
        type: WidgetTypes.BLOWER
      },
      {
        title: WidgetTitles[WidgetTypes.FLOW_DAILY_FLOWRATE],
        description: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Aspernatur reprehenderit animi blanditiis distinctio, autem repellat et fugiat nemo porro quidem, eos rem vel. Similique odit dignissimos ad fugiat, perspiciatis dolores!",
        type: WidgetTypes.FLOW_DAILY_FLOWRATE
      },
      {
        title: WidgetTitles[WidgetTypes.FLOW_DAILY_IRREGULARITY],
        description: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Aspernatur reprehenderit animi blanditiis distinctio, autem repellat et fugiat nemo porro quidem, eos rem vel. Similique odit dignissimos ad fugiat, perspiciatis dolores!",
        type: WidgetTypes.FLOW_DAILY_IRREGULARITY
      },
      {
        title: WidgetTitles[WidgetTypes.FLOW_VOLUME],
        description: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Aspernatur reprehenderit animi blanditiis distinctio, autem repellat et fugiat nemo porro quidem, eos rem vel. Similique odit dignissimos ad fugiat, perspiciatis dolores!",
        type: WidgetTypes.FLOW_VOLUME
      },
      {
        title: WidgetTitles[WidgetTypes.TEMPERATURE],
        description: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Aspernatur reprehenderit animi blanditiis distinctio, autem repellat et fugiat nemo porro quidem, eos rem vel. Similique odit dignissimos ad fugiat, perspiciatis dolores!",
        type: WidgetTypes.TEMPERATURE
      },
      {
        title: WidgetTitles[WidgetTypes.LOGS],
        description: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Aspernatur reprehenderit animi blanditiis distinctio, autem repellat et fugiat nemo porro quidem, eos rem vel. Similique odit dignissimos ad fugiat, perspiciatis dolores!",
        type: WidgetTypes.LOGS
      }
    ];

    return of(widgets);
  }

}
