import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-error404',
  templateUrl: './error404.component.html',
  styleUrls: ['../error.scss']

})
export class Error404Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  goBack(){
    history.back()
  }

}
