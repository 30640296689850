export enum Roles_Organization {
  ORGANIZATION_ADMIN = "ORGANIZATION_ADMIN",
  ORGANIZATION_TECHNOLOGIST = "ORGANIZATION_TECHNOLOGIST"
}

export enum RolesEnum {
  USER = "USER",
  ADMIN = "ADMIN",
  AG_MANAGER = "AG_MANAGER",
  AG_TECHNOLOGIST = "AG_TECHNOLOGIST",
  MAINTAINER_ADMIN = "MAINTAINER_ADMIN",
  MAINTAINER_DEALER = "MAINTAINER_DEALER",
  ORGANIZATION_ADMIN = "ORGANIZATION_ADMIN",
  ORGANIZATION_TECHNOLOGIST = "ORGANIZATION_TECHNOLOGIST"
}

