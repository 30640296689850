import { forwardRef, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ErrorCode, ErrorContent, PageLite } from "@cloud/shared";
import { ErrorsServiceApi } from "./errors.service.api";

@Injectable({
  providedIn: "root",
  useClass: forwardRef(() => ErrorsServiceApi)
})
export abstract class ErrorsAdapterService {

  public abstract getLastStationErrors(id: string, count: number, offset: number, filters: Map<string, any>): Observable<PageLite>;

  public abstract getErrorCodes(): Observable<ErrorCode[]>

  public abstract putErrorCode(errorCode: ErrorCode): Observable<ErrorCode>

  public abstract postErrorCode(errorCode: ErrorCode): Observable<ErrorCode>

  public abstract patchSelectedErrors(errors: ErrorContent[]): Observable<ErrorContent[]>;
}
