import { ErrorHandler, Injectable } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { catchError, ignoreElements, Observable, of, Subject } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class ErrorHandlerService extends ErrorHandler {
  private errorObserver = new Subject<string>();
  public errorSubscriber = this.errorObserver.asObservable();

  public handleHttpError(error: HttpErrorResponse): any {
    this.errorObserver.next(error.error.message || `Ошибка ${error.status}`);
    if (error.error instanceof ErrorEvent) {
      throw new Error("An error occurred: " + error.error.message);
    } else {
      throw new Error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`);
    }
    throw new Error("Something bad happened; please try again later.");
  }

  public override handleError(error: Error) {
    this.errorObserver.next(error.message || `Ошибка ${error.name}`);
  }

  public createErrorObservable(observable: Observable<any>): Observable<any> {
    return observable.pipe(
      ignoreElements(),
      catchError(error => {
        return of(error);
      })
    );
  }
}
