import { Injectable } from '@angular/core';
import { ErrorsService } from './errors.service';
import { ErrorsAdapterService } from './errors.adapter.service';
import { Observable } from 'rxjs';
import { ErrorCode, ErrorContent, PageLite } from "@cloud/shared";

@Injectable({
  providedIn: "root"
})
export class ErrorsDataService implements ErrorsService {

  errors$!: Observable<PageLite>;

  constructor(
    private api: ErrorsAdapterService
  ) {}

  createNewErrorCode(errorCode: ErrorCode): Observable<ErrorCode> {
        return this.api.postErrorCode(errorCode)
    }

  editErrorCode(errorCode: ErrorCode): Observable<ErrorCode> {
    return this.api.putErrorCode(errorCode)
    }

  getErrorCodes(): Observable<ErrorCode[]> {
    return this.api.getErrorCodes()
    }

	getStationErrors(id: string, count = 3, offset = 0, filters = new Map<string, any>()): Observable<PageLite> {
		return this.api.getLastStationErrors(id, count, offset, filters);
	}

  setSelectedErrors(errors: ErrorContent[]): Observable<ErrorContent[]> {
    return this.api.patchSelectedErrors(errors);
  }

}
