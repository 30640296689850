import {Injectable} from '@angular/core';
import {Specification, Station} from "@cloud/shared";
import {UpdateCoupledSpecificationsDto} from "../../dto/update-coupled-specifications.dto";

interface FormValue {
  parentId: string;
  childId: string;
  deleted: boolean
}

@Injectable({
  providedIn: 'root'
})
export class AdminMapperService {

  mapFormValueToCoupledSpecificationsRequest(station: Station, formValue:FormValue[]): UpdateCoupledSpecificationsDto[] {
    const stationSpecifications = this.findChangedSpecifications(station.specifications, formValue.map(value => value.parentId))
    return stationSpecifications.map(spec => {
      return {
        id: spec.id,
        coupled_specifications: spec.coupledSpecifications!.map(value => value.id)
      }
    })

  }

  private findChangedSpecifications(specifications: Specification[], parentIds: string[]): Specification[] {
    const result: Specification[] = []
    result.push(...specifications.filter(spec => {
      if (spec.specifications) result.push(...this.findChangedSpecifications(spec.specifications, parentIds))
      return parentIds.includes(spec.id)
    }))
    return result
  }
}
