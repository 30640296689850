import { Injectable } from '@angular/core';
import { ErrorContent } from '../../model/error.model';

@Injectable({
  providedIn: 'root'
})
export class ErrorsMapperService {

  mapErrorContentToIDs(errors: ErrorContent[]): string[] {
    return errors.map(err => err.message.id);
  }

}
