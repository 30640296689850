import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'markLetters'
})
export class MarkLettersPipe implements PipeTransform {

  transform(value: string, highlight: string): string {
    const re = new RegExp(highlight, 'gi')
    return value.replace(re, "<b>$&</b>");
  }

}
