import {Injectable} from "@angular/core";
import {ChartInterface, Line} from "../model/chart-interface.model";
import {ChartTypes, LineTypes} from "../enums/main.enum";
import {Chart, ChartConfiguration, ChartDataset} from "chart.js";
import colorLib from "@kurkle/color";
import zoomPlugin from "chartjs-plugin-zoom";
import {HttpClient} from "@angular/common/http";
import {EnvironmentApiUrlService} from "./environment-api-url.service";
import {forkJoin, Observable, take, tap} from "rxjs";
import {ru} from "date-fns/locale";
import {Metric} from "../model/metric.model";
import {TimestampService} from "./timestamp.service";

@Injectable({
  providedIn: "root"
})
export class ChartConstructorService {

  // tooltipTimer: any;

  //-------------------------------------------------------------------------------------
  //-----------------------------FETCHING------------------------------------------------
  //-------------------------------------------------------------------------------------


  zoomTimer: any

  constructor(private http: HttpClient,
              private envUrl: EnvironmentApiUrlService,
              private timestampService: TimestampService) {
    Chart.register(zoomPlugin);
  }

  private get xAxis() {
    return {
      position: "bottom",
      type: "time",
      time: {
        displayFormats: {
          year: "yyyy",
          quarter: "MMM yyyy",
          month: "MMM",
          day: "MMM d",
          hour: "MMM d HH:mm",
          minute: "HH:mm",
          second: "HH:mm:ss"
        }
      },
      ticks: {
        autoSkip: true,
        autoSkipPadding: 50,
        maxRotation: 0
      },
      adapters: {
        date: {
          locale: ru

        }
      }
    };
  }

  startFetch(chart: Chart, chartInterface: ChartInterface, threshold: number) {
    clearTimeout(this.zoomTimer)
    this.zoomTimer = setTimeout(() => this.fetch(chart, chartInterface, threshold), 400)
  }

  createMetricChartConfig(chartInterface: ChartInterface, threshold?: number): ChartConfiguration {
    const config = {
      type: chartInterface.type,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: this.createChartScales(chartInterface),
        interaction: {
          mode: "nearest",
          intersect: false,
          axis: "x"
        },
        elements: {
          point: {
            radius: chartInterface.type == ChartTypes.BUBBLE ? 5 : 0,
            hitRadius: 5
          }
        },
        plugins: {
          htmlLegend: {
            containerID: chartInterface.id
          },
          legend: {
            display: true
          },
          title: {
            display: true,
            text: chartInterface.name
          },
          tooltip: {
            callbacks: {
              title: (context: any) => {
                return `${new Date(context[0].parsed.x).toLocaleString('ru', { timeZone: this.timestampService.timeOffset! })} GMT+${this.timestampService.timeOffset?.slice(1, 3)}`;
              }
            }
          },
          zoom: this.setZoomConfiguration(chartInterface, threshold),
          //   annotation: {
          //     annotations: {
          //       line1: {
          //         type: "line",
          //         // yMax: widget.details.max,
          //         // yMin: widget.details.max,
          //         borderWidth: 1,
          //         borderColor: "rgba(255,13,13, 0.6)"
          //       }
          //   }
        }
      },
      data: {
        datasets: this.createMetricChartDataset(chartInterface)
      }
      // plugins: [htmlLegendPlugin],
    };

    // const max = axis.metric.details?.find(elem => elem.name.includes("MAX") && elem.unit == axis.metric.unit);
    // const min = axis.metric.details?.find(elem => elem.name.includes("MIN") && elem.unit == axis.metric.unit);

    return config as ChartConfiguration;
  }


  //-------------------------------------------------------------------------------------
  //-----------------------------METRIC CHART------------------------------------------------
  //-------------------------------------------------------------------------------------

  transparentize(value: any, opacity: number) {
    const alpha = opacity === undefined ? 0.5 : 1 - opacity;
    return colorLib(value).alpha(alpha).rgbString();
  }

  private fetch(chart: Chart, chartInterface: ChartInterface, threshold: number) {
    const {min, max} = chart.scales['x'];
    const source: Observable<Metric>[] = [];
    chartInterface.lines.forEach(value => {
      source.push(this.fetchData(min, max, value.metric, threshold));
    });
    forkJoin(source)
      .pipe(take(1))
      .pipe(tap(metrics => {
        chartInterface.lines.map((line, index) => {
          line.metric.events = metrics[index].events;
        });
      })).subscribe(() => this.updateDatasets(chart, chartInterface));
  }

  // private defineLine(type: 'max' | 'min'){
  //   // const max = axis.metric.details?.find(elem => elem.name.includes("MAX") && elem.unit == axis.metric.unit);
  //   // const min = axis.metric.details?.find(elem => elem.name.includes("MIN") && elem.unit == axis.metric.unit);
  //
  //   return {
  //
  //   }
  // }


  // callback: (tickValue: any) => {
  //   if (value.metric.unit !== "" && value.metric.unit !== "NONE") {
  //     if (tickValue == max?.value) return `max: ${tickValue} ${value.metric.unit}`;
  //     if (tickValue == min?.value) return `min: ${tickValue} ${value.metric.unit}`;
  //     return `${tickValue} ${value.metric.unit}`;
  //   } else {
  //     if (tickValue == max?.value) return `max: ${tickValue} ${value.metric.unit}`;
  //     if (tickValue == min?.value) return `min: ${tickValue} ${value.metric.unit}`;
  //     if (tickValue == 1) return `ON`;
  //     if (tickValue == 0) return `OFF`;
  //     return "";
  //   }
// }

  private updateDatasets(chart: Chart, chartInterface: ChartInterface) {
    chartInterface.lines.forEach((line, index) => {
      chart.data.datasets[index].data = line.metric.events!.map(event => {
        const x = event.timestamp;
        const y = event.value;
        return {x: x, y: y};
      });
    });
    chart.stop();
    chart.update("none");
  }

  //-------------------------------------------------------------------------------------
  //-----------------------------OTHER---------------------------------------------------
  //-------------------------------------------------------------------------------------

  private createChartScales(chartInterface: ChartInterface) {
    let scales = {
      x: this.xAxis
    };

    let uniqueUnitLines = chartInterface.lines;
    uniqueUnitLines = chartInterface.lines.filter((line, index, array) => {
      return array.findIndex(l => l.metric.unit === line.metric.unit) === index;
    });
    let newScales = {};
    uniqueUnitLines.map((axis, index, array) => {
      const max = axis.metric.details?.find(elem => elem.name.includes("MAX") && elem.unit == axis.metric.unit);
      const min = axis.metric.details?.find(elem => elem.name.includes("MIN") && elem.unit == axis.metric.unit);
      let y;
      if (index === 0) {
        y = {
          [`y${axis.metric.unit}`]: {
            display: true,
            suggestedMin: min?.value,
            suggestedMax: max?.value,
            position: "left",
            type: 'linear',
            // stack: "chart",
            ticks: {
              // maxTicksLimit: ticksNumber,
              // autoSkip: false,
              callback: (tickValue: any) => {
                if (axis.metric.unit !== "" && axis.metric.unit !== "NONE") {
                  if (tickValue == max?.value) return `max: ${tickValue} ${axis.metric.unit}`;
                  if (tickValue == min?.value) return `min: ${tickValue} ${axis.metric.unit}`;
                  return `${tickValue} ${axis.metric.unit}`;
                } else {
                  if (tickValue == max?.value) return `max: ${tickValue} ${axis.metric.unit}`;
                  if (tickValue == min?.value) return `min: ${tickValue} ${axis.metric.unit}`;
                  if (tickValue == 1) return `ON`;
                  if (tickValue == 0) return `OFF`;
                  return "";
                }
              }
            }
          }
        };
      } else {
        switch (index % 2) {
          case 0 :
            y = {
              [`y${axis.metric.unit}`]: {
                display: true,
                suggestedMin: min?.value,
                suggestedMax: max?.value,
                position: "left",
                type: 'linear',
                // stack: "chart",
                // offset: index > 2,
                ticks: {
                  // maxTicksLimit: ticksNumber,
                  // autoSkip: false,
                  callback: (tickValue: any) => {
                    if (axis.metric.unit !== "" && axis.metric.unit !== "NONE") {
                      if (tickValue == max?.value) return `max: ${tickValue} ${axis.metric.unit}`;
                      if (tickValue == min?.value) return `min: ${tickValue} ${axis.metric.unit}`;
                      return `${tickValue} ${axis.metric.unit}`;
                    } else {
                      if (tickValue == max?.value) return `max: ${tickValue} ${axis.metric.unit}`;
                      if (tickValue == min?.value) return `min: ${tickValue} ${axis.metric.unit}`;
                      if (tickValue == 1) return `ON`;
                      if (tickValue == 0) return `OFF`;
                      return "";
                    }
                  }
                },
                grid: {
                  drawOnChartArea: false // only want the grid lines for one axis to show up
                }
              }
            };
            break;
          case 1 :
            y = {
              [`y${axis.metric.unit}`]: {
                display: true,
                suggestedMin: min?.value,
                suggestedMax: max?.value,
                position: "right",
                type: 'linear',
                // stack: "chart",
                // offset: index > 2,
                ticks: {
                  // maxTicksLimit: ticksNumber,
                  // autoSkip: false,
                  callback: (tickValue: any) => {
                    if (axis.metric.unit !== "" && axis.metric.unit !== "NONE") {
                      if (tickValue == max?.value) return `max: ${tickValue} ${axis.metric.unit}`;
                      if (tickValue == min?.value) return `min: ${tickValue} ${axis.metric.unit}`;
                      return `${tickValue} ${axis.metric.unit}`;
                    } else {
                      if (tickValue == max?.value) return `max: ${tickValue} ${axis.metric.unit}`;
                      if (tickValue == min?.value) return `min: ${tickValue} ${axis.metric.unit}`;
                      if (tickValue == 1) return `ON`;
                      if (tickValue == 0) return `OFF`;
                      return "";
                    }
                  }
                },
                grid: {
                  drawOnChartArea: false // only want the grid lines for one axis to show up
                }
              }
            };
            break;
        }
      }
      newScales = Object.assign(newScales, y);
    });
    scales = Object.assign(scales, newScales);
    return scales;
  }

  private createMetricChartDataset(chartInterface: ChartInterface): ChartDataset[] {
    const datasets: ChartDataset[] = [];
    try {
      chartInterface.lines.forEach((line: Line, metricIndex) => {
        //todo refactor
        const max = line.metric.details?.find(elem => elem.name.includes("MAX") && elem.unit == line.metric.unit);
        const min = line.metric.details?.find(elem => elem.name.includes("MIN") && elem.unit == line.metric.unit);
        if (line.metric.events) {
          const data = line.metric.events.map(event => {
            const x = event.timestamp;
            const y = event.value;
            return {x: x, y: y};
          });
          const index = line.metric.unit;

          const dataset: ChartDataset = {
            label: line.name,
            data: data,
            yAxisID: `y${index}`,
            borderColor: line.color,
            backgroundColor: this.transparentize(line.color, 0.8),
            pointBackgroundColor: (context: any) => {
              const index = context.dataIndex;
              const value = context.dataset.data[index];
              if (max && min && (value?.y >= max?.value || value?.y <= min?.value)) return "rgb(255,13,13)";
              return line.color;
            },
            pointBorderColor: "#fff",
            pointHoverBackgroundColor: (context: any) => {
              const index = context.dataIndex;
              const value = context.dataset.data[index];
              if (max && min && (value?.y >= max?.value || value.y <= min?.value)) return "rgb(255,13,13)";
              return "#fff";
            },
            pointHoverBorderColor: "rgba(148,159,177,0.8)",

            stepped: line.type == LineTypes.STEPPED,
            tension: line.type == LineTypes.LINEAR ? 0.25 : 0,
            fill: chartInterface.lines.length <= 1
          };
          datasets.push(dataset);
        }
      });
      return datasets;
    } catch (err) {
      console.error(err);
      return [];
    }
  }

  private setZoomConfiguration(chartInterface: ChartInterface, threshold?: number) {
    return {
      pan: {
        enabled: true,
        modifierKey: "shift",
        mode: "xy",
        // threshold: 10,
        onPanComplete: (ctx: any) => {
          if (threshold) return this.startFetch(ctx.chart, chartInterface, threshold);
        }
      },
      zoom: {
        // limits: {
        //   x: { min: 10, max: 100 }
        // },
        wheel: {
          modifierKey: "ctrl",
          enabled: true,
          speed: 0.1,
        },
        drag: {
          enabled: true,
          threshold: 150
        },
        pinch: {
          enabled: true
        },
        mode: 'xy',
        onZoomComplete: (ctx: any) => {
          if (threshold) return this.startFetch(ctx.chart, chartInterface, threshold);
        }
        // overScaleMode: 'x',
      }
    };
  }

  private fetchData(min: number, max: number, metric: Metric, threshold: number): Observable<Metric> {
    return this.http.get<Metric>(this.envUrl.apiUrlV1 + `/metrics/${metric.id}/interval`, {
      params: {
        start: Number(min.toFixed(0)),
        end: Number(max.toFixed(0)),
        threshold: threshold
      }
    });
  }

}
