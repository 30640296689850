import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { BehaviorSubject, forkJoin, Observable } from "rxjs";
import { EnvironmentApiUrlService } from "./environment-api-url.service";
import { PassportService } from "./passport.service";
import { FormGroup } from "@angular/forms";
import { SpecLevels } from "../enums/main.enum";
import {
  ConstructorProject,
  ConstructorProjectWithOneTemplate,
  ConstructorTemplate,
  TemplateLight
} from "../model/project.model";
import { Page } from "../model/page.model";
import { Specification } from "../model/specification.model";
import { Detail } from "../model/detail.model";
import { Metric } from "../model/metric.model";

@Injectable({
  providedIn: "root"
})
export class ConstructorService {

  readonly emptyProject: ConstructorProject = {
    name: "", template: []
  };

  private currentProject: BehaviorSubject<ConstructorProject>;
  private projectHeader: BehaviorSubject<any>;
  private templates: BehaviorSubject<TemplateLight[]>;

  public currentProject$: Observable<ConstructorProject>;
  public projectHeader$: Observable<FormGroup>;


  constructor(private http: HttpClient,
              private envUrl: EnvironmentApiUrlService,
              private passportService: PassportService) {
    this.projectHeader = new BehaviorSubject<any>(null);
    this.currentProject = new BehaviorSubject<ConstructorProject>(this.emptyProject);
    this.templates = new BehaviorSubject<TemplateLight[]>([]);
    this.currentProject$ = this.currentProject.asObservable();
    this.projectHeader$ = this.projectHeader.asObservable();
  }

  getAllSpecificationsBySpecLevel(types: string[] = [SpecLevels.ALL], filter?: string, offset?: number, count?: number): Observable<ConstructorTemplate[][]> | Observable<Page> {
    if (types.includes(SpecLevels.ALL)) return this.http.get<Page>(this.envUrl.apiUrlV1 + "/constructor/specifications", {
      params: {
        type: types,
        filter: filter || "",
        offset: offset || 0,
        count: count || 0
      }
    });

    const requestList: Observable<ConstructorTemplate[]>[] = [];

    types.forEach(type => {
      const request = this.http.get<ConstructorTemplate[]>(this.envUrl.apiUrlV1 + "/constructor/specifications", {
        params: {
          type: type,
          filter: filter || "",
          offset: offset || 0,
          count: count || 0
        }
      });
      requestList.push(request);
    });
    return forkJoin(requestList);
  }

  getSpecificationById(id: string): Observable<ConstructorTemplate> {
    return this.http.get<ConstructorTemplate>(this.envUrl.apiUrlV1 + `/constructor/specifications/${id}`);
  }

  //todo deprecated
  doNextProject(project: ConstructorProject) {
    this.currentProject.next(project);
  }

  doNextProjectHeader(header: any) {
    this.projectHeader.next(header);
  }

  getSearchedSpecs(searchInput: string): Observable<Specification[]> {
    return this.http.get<Specification[]>("", {
      params: {
        query: searchInput
      }
    });
  }


  //todo deprecated
  // updateUserDraft(id: string, project: ConstructorTemplate[]): any {
  //   if (project) return this.http.put(this.envUrl.apiUrl + `/constructor/specifications/drafts/${id}`, project)
  // }
  // deleteUserDraft(id: string) {
  //   return this.http.delete(this.envUrl.apiUrl + `/constructor/specifications/drafts/${id}`)
  // }
  // getUserDraft(id: string): Observable<ConstructorProject> {
  //   return this.http.get<ConstructorProject>(this.envUrl.apiUrl + `/constructor/specifications/drafts/${id}`)
  // }

  getConstructorTemplatesBySpecLevel(spec_level?: string, name?: string): Observable<any> {
    let params: HttpParams = new HttpParams();
    if (spec_level) params = params.append("level", spec_level);
    if (name) params = params.append("name", name);
    return this.http.get(this.envUrl.apiUrlV1 + `/constructor/templates`, {
      params: params
    });
  }

  deleteTemplate(id: string) {
    return this.http.delete(this.envUrl.apiUrlV1 + `/constructor/templates/${id}`);
  }

  saveNewConstructorFromSubject(name: string, template: ConstructorTemplate): Observable<ConstructorTemplate> {
    const prj = {
      name: name,
      template: template
    };
    return this.http.post<ConstructorTemplate>(this.envUrl.apiUrlV1 + `/constructor/templates`, prj);
  }


  addNewDetailToSpecification(specId: string, value: Detail): Observable<Detail> {
    return this.http.put<Detail>(this.envUrl.apiUrlV1 + `/constructor/specifications/${specId}/details`, value);
  }

  addNewMetricToSpecification(specId: string, value: Metric): Observable<Metric> {
    return this.http.put<Metric>(this.envUrl.apiUrlV1 + `/constructor/specifications/${specId}/metrics`, value);
  }

  createNewSpecification(value: Specification): Observable<Specification> {
    return this.http.post<Specification>(this.envUrl.apiUrlV1 + `/constructor/specifications`, value);
  }

}
