import { forwardRef, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Widget } from "../../model/widget.model";
import { WidgetServiceMock } from "./widget.service.mock";
import { WidgetServiceApi } from "./widget.service.api";

@Injectable({
  providedIn: "root",
  useClass: forwardRef(() => WidgetServiceApi)
})

export abstract class WidgetAdapterService {

  public abstract getDailyIrregularityForLastWeekRequest(id: string): Observable<Widget>;

  public abstract getDailyFlowrateForLastWeekRequest(id: string): Observable<Widget>;

  public abstract getFlowmeterVolumeRequest(id: string): Observable<Widget>;

  public abstract getTemperatureRequest(id: string): Observable<Widget>;

  public abstract getBlowerRequest(id: string): Observable<Widget>;

  public abstract getAAMPPumpRequest(id: string): Observable<Widget>;
}
