export enum WidgetTypes {
  AAMP_PUMPS = "aamp_pumps",
  TEMPERATURE = "temperature",
  FLOW_DAILY_IRREGULARITY = "flow_daily_irregularity",
  FLOW_DAILY_FLOWRATE = "flow_daily_flowrate",
  FLOW_VOLUME = "flow_volume",
  BLOWER = "blower",
  LOGS = "logs",
}

export enum WidgetTitles {
  aamp_pumps = "Токи насосов рециркуляции",
  temperature = "Температура за последнюю неделю",
  flow_daily_irregularity = "Распределение коэффициента суточной неравномерности по дням недели",
  flow_daily_flowrate = "Распределение суточного расхода по дням недели",
  flow_volume = "Накопленный объем за последнюю неделю",
  blower = "Параметры работы воздуходувки",
  logs = "Уведомления",
}