import { Injectable } from "@angular/core";
import { ChartTypes, Widget } from "@cloud/shared";
import { Chart, ChartConfiguration, ChartDataset } from "chart.js";
import { customTooltips } from "./plugins.service";
import { WidgetConstructorAbstractService } from "./widget-constructor.abstract.service";

@Injectable({
  providedIn: "root"
})
export class IrregularityService implements WidgetConstructorAbstractService {

  createDemoWidgetConfig(): ChartConfiguration {
    const widget: Widget = {
      id: "device_id",
      details: { min: 0, max: 2, legend: "Коэффициент суточной неравномерности" },
      data: [
        { label: "05.03", value: 1 },
        { label: "06.03", value: 1.4 },
        { label: "07.03", value: 2.2 },
        { label: "08.03", value: 0.6 },
        { label: "09.03", value: 1.12 },
        { label: "10.03", value: 1.7 },
        { label: "11.03", value: 1.8 }
      ]
    };
    return this.createWidgetConfig(widget);
  }

  createWidgetConfig(widget: Widget): ChartConfiguration {
    return {
      type: ChartTypes.BAR,
      data: this.createWidgetDataset(widget),
      options: this.createWidgetOptions(widget)
    };
  }

  private createWidgetOptions(widget: Widget): ChartConfiguration["options"] {
    return {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        x: {
          stacked: false
        },
        y: {
          beginAtZero: true,
          suggestedMax: widget.details.max as number,
          suggestedMin: widget.details.min as number,
          ticks: {
            stepSize: 0.5
          }
        }
      },
      plugins: {
        legend: {
          display: true
        },
        title: {
          display: false
        },
        tooltip: {
          enabled: false,
          mode: "index",
          position: "nearest",
          external: customTooltips
        },
        annotation: {
          annotations: {
            line1: {
              type: "line",
              yMax: widget.details.max,
              yMin: widget.details.max,
              borderWidth: 1,
              borderColor: "rgba(255,13,13, 0.6)"
            },
            line2: {
              type: "line",
              yMax: widget.details.min,
              yMin: widget.details.min,
              borderWidth: 1,
              borderColor: "rgba(255,13,13, 0.6)"
            },
            line3: {
              type: "line",
              yMax: 1,
              yMin: 1,
              borderWidth: 2,
              borderColor: "rgba(0,123,193,0.6)"
            }
          }
        }
      }
    };
  }

  private createWidgetDataset(widget: Widget): ChartDataset<any> {
    const labels = widget.data.map(point => point.label);
    return {
      labels: labels,
      datasets: [
        {
          label: widget.details.legend || "Виджет",
          data: widget.data.map(point => {
            return [1, point.value];
          }),
          backgroundColor: (ctx: any) => {
            if (ctx.parsed.y! < widget.details.min! || ctx.parsed.y > widget.details.max!) return "rgb(255,13,13)";
            return "#007bc1";
          }
        }
      ]
    };
  }

  updateWidgetDataset(chart: Chart, widget: Widget) {
    chart.data.datasets.forEach((dataset: any) => {
      dataset.data = chart.data.labels!.map((_value, index) => [1, widget.data[index].value]);
    });
    chart.update();
  }
}
