import {Injectable} from "@angular/core";
import {ErrorHandlerService} from "./error-handler.service";
import {Subject} from "rxjs";
import {AlertType} from "../enums/main.enum";
import {Alert} from "../model/alert.model";


@Injectable({
  providedIn: "root"
})
export class EventHandlerService {
  private alertObserver = new Subject<Alert>();
  alertSubscriber = this.alertObserver.asObservable();

  constructor(private errorhandler: ErrorHandlerService) {
    errorhandler.errorSubscriber.subscribe(err => {
      this.throwAlert(AlertType.danger, err);
    });
  }

  throwAlert(type: AlertType, message: string, isAlert = true) {
    const alert = {
      type: type,
      message: message,
      isAlert: isAlert
    };
    this.alertObserver.next(alert);
  }


}
