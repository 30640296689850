import { forwardRef, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { WidgetLight } from "@cloud/shared";
import { DashboardServiceMock } from "./dashboard.service.mock";
import { DashboardModel } from "../../model/dashboard.model";

@Injectable({
  providedIn: "root",
  useClass: forwardRef(() => DashboardServiceMock)
})

export abstract class DashboardAdapterService {

  abstract getDashboardByUserId(id: string): Observable<DashboardModel[]>

  // abstract updateDashboardWithNewWidget(user_id: string, dashboard: DashboardModel[], widget: WidgetLight): Observable<DashboardModel[]>;

  abstract putDashboardChanges(user_id: string, dashboard: DashboardModel[]): Observable<DashboardModel[]>;

  // abstract getGridLayoutItemByItemId(id: string): Observable<LayoutItem>
}
