import { Injectable } from "@angular/core";
import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from "@angular/forms";
import { HttpClient } from "@angular/common/http";
import { StorageService } from "./storage.service";
import { EnvironmentApiUrlService } from "./environment-api-url.service";
import { BehaviorSubject, Observable, of, Subject, tap } from "rxjs";
import { Router } from "@angular/router";
import { Employee } from "../model/employee.model";
import { AuthService } from "./auth.service";
import { User, UserRegister } from "../model/user.model";
import { Organization } from "../model/organization.model";
import { ta } from "date-fns/locale";
import { Roles_Organization } from "../enums/roles.enum";


@Injectable({
  providedIn: "root"
})
export class PassportService {

  private userInfoObserver: Subject<User> = new BehaviorSubject<User>({
    email: "",
    firstname: "",
    lastname: "",
    phone: ""
  });
  userInfoSubscriber$: Observable<User> = this.userInfoObserver.asObservable();

  private employeeObserver = new BehaviorSubject<Employee[]>([]);
  public employeeSubscriber$: Observable<Employee[]> = this.employeeObserver.asObservable();


  private _selectedEmployee!: Employee;

  private _organization!: Organization;


  constructor(private http: HttpClient,
              private storageService: StorageService,
              private envUrl: EnvironmentApiUrlService,
              private router: Router,
              private auth: AuthService) {
  }

  //========================================================================
  //                        User
  //========================================================================

  registerUser(user: UserRegister): Observable<User> {
    return this.http.post<User>(this.envUrl.apiUrlV1 + `/persons/register`, user);
  }

  patchUserInfo(user: User): Observable<User> {
    return this.http.patch<User>(this.envUrl.apiUrlV1 + `/persons/${user.id}`, user).pipe(tap(usr => this.userInfoObserver.next(usr)));
  }

  getUserInfo(id: string): Observable<User> {
    return this.http.get<User>(this.envUrl.apiUrlV1 + `/persons/${id}`).pipe(tap(usr => this.userInfoObserver.next(usr)));
  }

  // getUserByEmail(email: string): Observable<User> {
  //   return this.http.get<User>(this.envUrl.apiUrl + `/persons`, {
  //     params: {
  //       email: email
  //     }
  //   })
  // }

  public get userId() {
    const { sub } = this.auth.identityClaims;
    return sub;
  }

  public get roles(): any {
    return this.auth.roles;
  }

  //========================================================================
  //                        Organization
  //========================================================================

  registerOrganization(organization: Organization): Observable<Organization> {
    return this.http.post<Organization>(this.envUrl.apiUrlV1 + `/companies/register`, organization);
  }

  // getEmployeeById(id: string): Observable<Employee> {
  //   return this.http.get<Employee>(this.envUrl.apiUrl + ``);
  // }

  getOrganizationByAdminId(id: string): Observable<Organization> {
    return this.http.get<Organization>(this.envUrl.apiUrlV1 + `/companies/admin/${id}`)
      .pipe(tap(({ employees }) => {
        this.emitEmployees(employees as Employee[]);
      }))
      .pipe(tap(({ employees, ...organization }) => {
        this.organization = organization;
      }));

  }

  getOrganizationEmployeesById(id: string): Observable<Employee[]> {
    return this.http.get<Employee[]>(this.envUrl.apiUrlV1 + `/companies/employee`, {
      params: {
        id: id
      }
    });
  }

  addEmployeeToOrganization(value: any, id: string): Observable<Employee> {
    return this.http.post<Employee>(this.envUrl.apiUrlV1 + `/companies/${id}/employees`, value);
  }

  changeOrganizationInfo(organization: Organization): Observable<Organization> {
    return this.http.patch<Organization>(this.envUrl.apiUrlV1 + `/companies/${organization.id}`, {
      name: organization.name,
      address: organization.address
    });
  }

  deleteEmployee(employee: Employee): Observable<any> {
    return this.http.delete(this.envUrl.apiUrlV1 + `/companies/employees/${employee.id}`);
  }

  assignRoleToEmployee(id: string, role: string): Observable<any> {
    return this.http.patch(this.envUrl.apiUrlV1 + `/employee/${id}`, {
      role: role
    });
  }

  // @PatchMapping(value = "/employee/{id}"

  public get employees(): Employee[] {
    return this.employeeObserver.value;
  }

  public emitEmployees(value: Employee[]) {
    this.employeeObserver.next(value);
  }


  get selectedEmployee(): Employee {
    return this._selectedEmployee;
  }

  set selectedEmployee(value: Employee) {
    this._selectedEmployee = value;
  }

  get organization(): Organization {
    return this._organization;
  }

  set organization(value: Organization) {
    this._organization = value;
  }

  //========================================================================
  //                        Other
  //========================================================================

  // Сравнение полей для пароля
  checkIfMatchingPasswords(passwordKey: string, passwordConfirmationKey: string) {
    return (group: FormGroup) => {
      const passwordInput = group.controls[passwordKey],
        passwordConfirmationInput = group.controls[passwordConfirmationKey];
      if (passwordInput.value !== passwordConfirmationInput.value) {
        return passwordConfirmationInput.setErrors({ mismatch: true });
      } else {
        return passwordConfirmationInput.setErrors(null);
      }
    };
  }


  passwordMatchValidator: ValidatorFn = (group: AbstractControl): ValidationErrors | null => {
    const passwordInput = group.get("password") as AbstractControl<any>;
    const passwordConfirmationInput = group.get("passwordConfirmation") as AbstractControl<any>;
    return passwordInput.value === passwordConfirmationInput.value ? null : { mismatch: true };
  };

}
