import { Observable } from "rxjs";
import { NotificationModel } from "../../model/notification.model";
import { NotificationsService } from "./notifications.service";
import { NotificationsAdapterService } from "./notifications.adapter.service";
import { forwardRef, Injectable } from "@angular/core";
import { NotificationsServiceMock } from "./notifications.service.mock";


@Injectable({
  providedIn: "root",
})
export class NotificationsDataService implements NotificationsService {

  constructor(private api: NotificationsAdapterService) {
  }

  getUserNotifications(id: string): Observable<NotificationModel[]> {
    return this.api.getNotifications(id);
  }

  editUserNotifications(id: string, formValue: NotificationModel[]): Observable<NotificationModel[]> {
    return this.api.putNotifications(id, formValue)
  }

}
