import {
  AfterContentInit, AfterViewInit,
  Component,
  ComponentRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef
} from "@angular/core";
import { Specification } from "@cloud/shared";
import { SpecificationMenuService } from "../../../../shared/services/specifications-menu/specification-menu.service";
import { specificationMenuAnimation } from "../../../../shared/animations/specifications-menu.animation";

@Component({
  selector: "app-column",
  templateUrl: "./column.component.html",
  styleUrls: ["./column.component.scss"],
  animations: [specificationMenuAnimation]
})
export class ColumnComponent implements AfterViewInit {

  @Input() specs!: Specification[];
  @Input() container!: ViewContainerRef;
  @Input() columns!: Array<ComponentRef<any>>;
  @Input() level!: number;
  @Input() activatedSpec: any;

  @ViewChild("metrics", { read: ViewContainerRef }) metricsContainer!: ViewContainerRef;

  constructor(private service: SpecificationMenuService) {
  }

  ngAfterViewInit(): void {
    setTimeout(() => this.service.emitColumns(true), 5);
  }

  clg(spec: any) {
    console.log(spec);
  }


  renderColumn(item: Specification) {
    this.service.emitSpecification(item);
    this.columns = this.service.renderColumn(item.specifications, this.level + 1, this.container, this.columns);
    this.columns[this.level].instance.activatedSpec = item;

    //___________________DEPRECATED___________________

    // this.metricsContainer.clear()
    // if (item.metrics && item.details) {
    // const component = this.metricsContainer!.createComponent(MetricsColumnComponent)
    // component.setInput('metrics', item.metrics)
    // component.setInput('details', item.details)
    // }
  }

}
