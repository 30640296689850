import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, retry, tap, timer } from "rxjs";
import { Router } from "@angular/router";
import { ErrorHandlerService } from "../services/error-handler.service";
import { AuthService } from "../services/auth.service";
import { AlertType } from "../enums/main.enum";
import { EventHandlerService } from "../services/event-handler.service";

@Injectable()
export class ErrorsInterceptor implements HttpInterceptor {
  maxRetryAttempts = 2;

  constructor(private router: Router,
              private errorHandler: ErrorHandlerService,
              private eventHandler: EventHandlerService,
              private auth: AuthService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req)
      .pipe(retry({
        count: this.maxRetryAttempts,
        delay: (_error, retryCount) => timer(1000 * retryCount)
      }))
      .pipe(tap({
        error: err => {
          switch (err.status) {
            case 0: {
              this.eventHandler.throwAlert(AlertType.danger, "Связь с сервером прервана. Проверьте подключение к интернету или обратитесь к администратору.");
              break;
            }
            case 401:
              this.auth.logout();
              this.router.navigate([""]).then(() => this.auth.runInitialLoginSequence());
              break;
            case 403:
              this.router.navigate(["/403"])
                .then(() => console.error("У вас нет доступа к запрашиваемому ресурсу."));
              break;
            case 500:
            case 503:
            case 504:
              this.eventHandler.throwAlert(AlertType.danger, "Сервис временно недоступен. Пожалуйста, зайдите позднее.");
              break;
            default:
              this.errorHandler.handleHttpError(err);
              break;
          }
        }
      }));
  }
}
